/**
 * 对象(键值对)
 */
import qs from 'qs';

export default {
  filter(dict) {
    let d = {};
    for (const k in dict) {
      switch (dict[k]) {
        case 0:
        case 0.0:
        case '0':
        case '':
        case false:
        case null:
        case undefined:
          break;
        default:
          d[k] = dict[k];
          break;
      }
    }
    return d;
  },

  ksort(dict) {
    let d = {};
    const keys = Object.keys(dict).sort();
    keys.forEach((v) => {
      d[v] = dict[v];
    });
    return d;
  },

  createHttpQuery(dict) {
    // let pairs = [];
    // for (const k in dict) {
    //   const v = encodeURIComponent(dict[k]);
    //   pairs.push(`${k}=${v}`);
    // }
    // return pairs.join('&').toLowerCase();
    return qs.stringify(dict).toLowerCase();
  },
};
