<template>
  <div class="topup">
    <input type="password" class="form-control" name="SecretOld" style="display: none" />
    <!-- 顶部 -->
    <div class="top-nav" id="topNav">
      <!-- 主要内容 -->
      <div class="top-nav-content">
        <!-- 左部logo -->
        <div class="top-nav-img" @click="clilogo">
          <img width="81" height="31" src="../assets/img/logo-big2.png" alt="logo图标" />
        </div>
        <div class="searchandmap">
          <!-- 中部导航标签/搜索 -->
          <div
            id="navSerch"
            class="top-nav-serch"
            ref="search"
            @click="YS.goDown($event, 'navMenu')"
            @mouseenter="YS.goDown($event, 'navMenu')"
            @mouseleave="
              !focusInput &&
                $store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'navMenu' })
            "
          >
            <!-- 导航字体 -->
            <div class="tag_span-parent">
              <span class="tag-span">导航</span>
            </div>
            <!-- 下拉 -->
            <div class="serch-updown">
              <!-- <img class="tag-img" height="10" width="13" src="../assets/img/down.png" /> -->
              <!-- <div
                class="map_icon"
                :style="{
                  backgroundImage:
                    $store.state.showWindows.navMenu == false
                      ? 'url(' + white.mapimg.up + ')'
                      : 'url(' + white.mapimg.down + ')',
                }"
              ></div> -->

              <svg
                class="top-down-svg"
                :class="{ 'top-down-svg-180': $store.state.showWindows.navMenu }"
                width="20"
                height="20"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37 18L25 30L13 18"
                  stroke="#fff"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <!-- 导航按钮菜单-主菜单 -->
            <transition name="show" v-if="isNight">
              <div class="poptip" v-show="$store.state.showWindows.navMenu">
                <div class="frame-body">
                  <template v-for="(item, index) in menutotal">
                    <a
                      :key="index"
                      :href="
                        YS.returnURL(
                          {
                            path: '/fil',
                            query: { category_id: item.id },
                          },
                          true,
                        )
                      "
                      target="_blank"
                      class="frame-item"
                      style="justify-content: center; align-items: center"
                      @mouseover="goVice($event, index)"
                    >
                      <span class="frame-span">
                        {{ item.name }}
                      </span>
                    </a>
                  </template>
                </div>
              </div>
            </transition>
            <transition name="show" v-else>
              <div class="poptip" v-show="$store.state.showWindows.navMenu">
                <!-- <span class="poptip-arrow poptip-arrow-top">
                  <em>◆</em>
                  <i>◆</i>
                </span> -->
                <div class="frame-body">
                  <template v-for="(item, index) in menutotal">
                    <a
                      :href="
                        YS.returnURL(
                          {
                            path: '/fil',
                            query: { parent_category_id: item.id },
                          },
                          true,
                        )
                      "
                      target="_blank"
                      class="frame-item"
                      v-if="item.show && item.symbol !== 'children'"
                      :key="index"
                      @mouseover="goVice($event, index)"
                    >
                      <!-- @click="clitip(item.id, item.pid, index)" -->
                      <div class="frame-item-left">
                        <span class="frame-span">{{ item.name }}</span>
                      </div>
                      <div class="frame-item-right">
                        <img src="../assets/img/right4.png" alt="跳转大分类" />
                      </div>
                    </a>
                    <a
                      :href="childURL.CHILD_WEB_RUL"
                      target="_blank"
                      class="frame-item"
                      v-if="item.show && item.symbol === 'children'"
                      :key="index"
                      @mouseover="goVice($event, index, true)"
                    >
                      <!-- @click="clitip(item.id, item.pid, index)" -->
                      <div class="frame-item-left">
                        <span class="frame-span">{{ item.name }}</span>
                      </div>
                    </a>
                  </template>
                </div>
                <!-- 导航按钮菜单-副菜单 -->
                <div
                  class="vice"
                  v-show="
                    (viceshow && $store.state.showWindows.navMenu) ||
                    $store.state.showWindows.navVice
                  "
                  :style="{ top: vicetop + 'px' }"
                >
                  <div class="vice-margin">
                    <div class="vice-left">
                      <template v-for="(item, index) in menuVice">
                        <a
                          :href="
                            YS.returnURL(
                              {
                                path: '/fil',
                                query: { parent_category_id: item.pid, category_id: item.id },
                              },
                              true,
                            )
                          "
                          target="_blank"
                          class="vice-body"
                          v-show="index % 2 == 0"
                          :key="index"
                        >
                          <!-- @click="clivice(item.id, item.pid, index + 1)" -->
                          <span class="vice-span">
                            {{ item.name }}
                          </span>
                        </a>
                      </template>
                    </div>
                    <div class="vice-right">
                      <template v-for="(item, index) in menuVice">
                        <a
                          :href="
                            YS.returnURL(
                              {
                                path: '/fil',
                                query: { parent_category_id: item.pid, category_id: item.id },
                              },
                              true,
                            )
                          "
                          target="_blank"
                          class="vice-body"
                          v-show="index % 2 != 0"
                          :key="index"
                        >
                          <!-- @click="clivice(item.id, item.pid, index + 1)" -->
                          <span class="vice-span">
                            {{ item.name }}
                          </span>
                        </a>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <!-- 中部搜索框 -->
          <div class="top-nav-input">
            <!-- @input="getSimilar" -->
            <input
              @click="clickSearchinput($event)"
              class="tag-input"
              v-model="searchkeyword"
              autocomplete="new-password"
              @keyup.enter="Clisearch(false)"
              @focus="focusSearch(true)"
              @blur="focusSearch(false)"
            />
            <button class="tag-button" @click="Clisearch(false)">
              <i>
                <img
                  class="search-icon"
                  height="13"
                  width="13"
                  src="../assets/img/search.png"
                  alt="搜索图标"
                />
              </i>
              <span class="tag-search-span">搜索</span>
            </button>
            <transition name="show">
              <!-- 搜索框下拉 -->
              <div class="search-body" v-show="$store.state.showWindows.navSearch">
                <div
                  class="search-menu"
                  v-show="
                    (similarList && similarList.length && similarShow) ||
                    (searchTop && searchTop.length && !searchkeyword)
                  "
                >
                  <div class="search-top">
                    <div class="search-top-similar" v-show="searchkeyword && similarShow">
                      <ul class="top-nodes-ul">
                        <li class="similar_li" v-for="(item, index) in similarList" :key="index">
                          <!-- <img src="../assets/img/top_video-1.png" /> -->
                          <!-- <div class="similar_li-icon"></div> -->
                          <a
                            :href="
                              YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)
                            "
                          >
                            {{ item.title }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="search-top-title" v-show="searchTop[0] && !searchkeyword">
                      <div class="search-top-left">
                        <span>历史搜索记录</span>
                      </div>
                      <div class="search-top-right" @click="clearsearch">
                        <span>清空</span>
                        <!-- <img height="12" src="../assets/img/del3.png" /> -->
                        <div class="top_del"></div>
                      </div>
                    </div>
                    <div class="search-top-nodes" v-show="!searchkeyword">
                      <ul class="top-nodes-ul">
                        <li
                          v-for="(item, index) in searchTop"
                          :key="index"
                          @click="Clisearch(item)"
                        >
                          <a>{{ item }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- <hr /> -->
                  <div
                    class="search-bottom"
                    v-show="(!searchkeyword || !similarShow) && keywork_list && keywork_list.length"
                  >
                    <div class="search-top-title">
                      <div class="search-top-left"><span>热门搜索</span></div>
                    </div>
                    <div class="search-top-nodes">
                      <ul class="top-nodes-ul">
                        <li
                          v-for="(item, index) in keywork_list"
                          :key="index"
                          @click="Clisearch(item)"
                        >
                          <span data-test="index">{{ index + 1 }}、</span>
                          <a href="javascript:void(0)">{{ item }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <!-- 右部右上角 -->
        <div class="top-nav-right">
          <div class="right-content">
            <!-- 会员 -->
            <div
              class="right-box right-a"
              @click="YS.goDown($event, 'navNT')"
              @mouseenter="YS.goDown($event, 'navNT')"
              @mouseleave="
                $store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'navNT' })
              "
            >
              <!-- <img class="right-img" src="../assets/img/vip3.png" /> -->
              <div class="ri_box">
                <div class="right-img ri_1"></div>
                <span class="right-span">会员</span>
              </div>
              <transition name="show">
                <!-- 会员下拉菜单弹窗 -->
                <div class="vip-tip" v-show="$store.state.showWindows.navNT">
                  <!-- <span class="poptip-arrow poptip-arrow-top">
                    <em>◆</em>
                    <i>◆</i>
                  </span> -->
                  <div class="vip-tip-top">
                    <div class="vtp-left"><span>还不是VIP？</span></div>
                    <div class="vtp-right" @click="wantVIP">
                      <a>立即开通</a>
                    </div>
                  </div>
                  <div class="vip-tip-bottom" @click="wantVIP">
                    <div class="vt-list">
                      <img
                        height="14"
                        width="14"
                        src="../assets/img/vlist1.png"
                        alt="过滤广告图标"
                      />
                      <span>过滤广告</span>
                    </div>
                    <div class="vt-list">
                      <img
                        height="14"
                        width="14"
                        src="../assets/img/vlist2.png"
                        alt="观看超清视频图标"
                      />
                      <span>观看超清视频</span>
                    </div>
                    <div class="vt-list">
                      <img
                        height="14"
                        width="14"
                        src="../assets/img/vlist3.png"
                        alt="跳过片头片尾图标"
                      />
                      <span>跳过片头片尾</span>
                    </div>
                    <div class="vt-list">
                      <img
                        height="14"
                        width="14"
                        src="../assets/img/vlist4.png"
                        alt="2倍速度播放图标"
                      />
                      <span>2倍速度播放</span>
                    </div>
                    <div class="vt-list">
                      <img
                        height="14"
                        width="14"
                        src="../assets/img/vlist5.png"
                        alt="尊贵身份标识图标"
                      />
                      <span>尊贵身份标识</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <!-- 福利 -->
            <!-- <div
              class="right-box right-b"
              @click="YS.goDown($event, 'navWF')"
              @mouseenter="YS.goDown($event, 'navWF')"
              @mouseleave="
                $store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'navWF' })
              "
            >
              <div class="ri_box">
                <div class="right-img ri_2"></div>
                <span class="right-span">福利</span>
              </div>
              <transition name="show">
                <div class="welfare-tip" v-show="$store.state.showWindows.navWF">
                  <div class="welfare-body">
                    <div class="welfare-li">
                      <img height="42" width="42" src="../assets/img/avatar1-32.png" />
                      <span>每日签到</span>
                    </div>
                    <div class="welfare-li" @click="YS.routetogo({ path: '/task' })">
                      <img height="42" width="42" src="../assets/img/avatar1-32.png" />
                      <span>任务中心</span>
                    </div>
                    <div class="welfare-li">
                      <img height="42" width="42" src="../assets/img/avatar1-32.png" />
                      <span>兑换商店</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div> -->
            <!-- 观看记录 -->
            <div
              class="right-box right-c"
              @click="YS.goDown($event, 'navWatch')"
              @mouseenter="YS.goDown($event, 'navWatch')"
              @mouseleave="
                $store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'navWatch' })
              "
            >
              <!-- v-if="$store.state.islogin" -->
              <!-- <img class="right-img" src="../assets/img/recording3.png" /> -->
              <div class="ri_box" @click="YS.routetogo({ path: '/history' })">
                <div class="right-img ri_3"></div>
                <span class="right-span rs_3">记录</span>
              </div>
              <transition name="show">
                <!-- 观看记录下拉菜单弹窗 -->
                <div class="watch-tip" v-show="$store.state.showWindows.navWatch">
                  <!-- <span class="poptip-arrow poptip-arrow-top">
                    <em>◆</em>
                    <i>◆</i>
                  </span> -->
                  <div class="watch-body">
                    <div class="watch_list_box">
                      <template v-for="(item, index) in playList">
                        <div
                          class="watch-list"
                          @click="clihis(index, item.video_id)"
                          v-if="index < 8"
                          :key="index"
                          v-show="topwatch"
                        >
                          <span class="watch-left">
                            {{ item.video_title }}
                            <span v-show="item.multiple_fragment && item.video_fragment_symbol">
                              第{{ item.video_fragment_symbol }}集
                            </span>
                            <span v-show="item.video_duration && item.elapsed">
                              {{ Math.floor((item.elapsed * 100) / item.video_duration) }}%
                            </span>
                          </span>
                          <span class="watch-right-a">{{ item.video_remark }}</span>
                          <span class="watch-right-b">
                            {{ YS.toTimeNow(item.create_time) }}
                          </span>
                        </div>
                      </template>
                      <div class="none_news_message" v-show="!playList">
                        <img
                          src="../assets/img/logo2x.png"
                          height="42"
                          width="112"
                          alt="没有内容"
                        />
                        <span>您还没有播放记录</span>
                      </div>
                    </div>
                    <div class="watch-bottom" v-show="playList">
                      <div class="wb-left" @click="clearhis">
                        <a>清空</a>
                        <!-- <img src="../assets/img/del3.png" /> -->
                        <div class="top_del"></div>
                      </div>
                      <a @click="YS.routetogo({ path: '/history' })">
                        <!-- @click="YS.routetogo({ path: '/history' })" -->
                        <div class="wb-right">
                          <a v-show="islogin">更多</a>
                          <!-- <img src="../assets/img/right.png" alt="下一页" /> -->
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <!-- 通知 -->
            <div
              class="right-box right-d"
              @click="YS.goDown($event, 'notice')"
              @mouseenter="YS.goDown($event, 'notice')"
              @mouseleave="
                $store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'notice' })
              "
              v-if="$store.state.islogin"
            >
              <!-- <img class="right-img" src="../assets/img/notice3.png" /> -->
              <div class="ri_box" @click="YS.routetogo({ path: '/message' })">
                <div class="right-img ri_4"></div>
                <span class="right-span">通知</span>
              </div>
              <div class="bubble" v-if="message_total">
                <span class="bubble-span">{{ message_total }}</span>
              </div>
              <transition name="show">
                <!-- 通知下拉菜单弹窗 -->
                <div class="notice-tip" v-show="$store.state.showWindows.notice">
                  <!-- <span class="poptip-arrow poptip-arrow-top">
                    <em>◆</em>
                    <i>◆</i>
                  </span> -->
                  <div class="notice-top">
                    <!-- <a class="nt-left ntt" @click="noticetagChange($event, 0)">收藏夹</a> -->
                    <a class="nt-right ntt notice-act" @click="noticetagChange($event, 1)">
                      消息&通知
                    </a>
                  </div>

                  <div class="notice-body">
                    <div class="nb_box">
                      <template v-for="(item, index) in $store.state.message.message_list">
                        <div
                          class="notice-nodes"
                          v-if="index < 6"
                          :key="index"
                          @click="cliNotice(index, item.id)"
                          v-show="tophis"
                        >
                          <div class="NN-left">
                            <div v-show="!item.read_time" class="unread"></div>
                            <span>{{ item.content }}</span>
                          </div>
                          <div class="NN-right">
                            <span>{{ YS.toTime4(item.create_time) }}</span>
                          </div>
                        </div>
                      </template>
                      <div class="none_news_message" v-show="!$store.state.message.message_list">
                        <img
                          src="../assets/img/logo2x.png"
                          height="42"
                          width="112"
                          alt="没有消息内容"
                        />
                        <span>您还没有收到新的更新消息</span>
                      </div>
                    </div>

                    <div class="notice-bottom" v-show="$store.state.message.message_list">
                      <div class="wb-left" @click="clearmess">
                        <a>全部已读</a>
                        <!-- <img src="../assets/img/del3.png" /> -->
                        <div class="top_del"></div>
                      </div>
                      <a @click="YS.routetogo({ path: '/message' })">
                        <div class="wb-right">
                          <a>更多</a>
                          <!-- <img src="../assets/img/right.png" alt="下一页" /> -->
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <!-- 注册/登录 -->
            <div
              class="right-box right-e right_user"
              @click="clickUser"
              @mouseenter="YS.goDown($event, 'user')"
              @mouseleave="
                $store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'user' })
              "
            >
              <!-- <img class="right-img" src="../assets/img/avatar1-32.png" /> -->
              <div class="ri_box ri_box_user">
                <!-- :style="{
                    backgroundImage:
                      'url(' +
                      ($store.state.user.avatar ? $store.state.user.avatar : userimg) +
                      ')',
                  }" -->
                <div class="right-user ri_5" :class="{ 'scale-h': !$store.state.user.avatar }">
                  <img
                    :src="$store.state.user.avatar ? $store.state.user.avatar : ''"
                    :onerror="$store.state.defaulava"
                    alt="用户头像"
                  />
                </div>
                <span class="right-span right-user-span">
                  {{
                    $store.state.user.nickname
                      ? $store.state.user.nickname
                      : $store.state.user.username
                      ? $store.state.user.username
                      : $store.state.user.email
                      ? $store.state.user.email
                      : '注册/登录'
                  }}
                </span>
              </div>
              <!-- 用户名下拉弹窗 -->
              <!-- <transition name="show">
                <div
                  class="user-tip"
                  v-show="$store.state.showWindows.user && islogin"
                  @click="cliUserDown"
                >
                  <div class="user_tip_box" v-show="isvip" data-type="vip">
                    <img src="../assets/img/topa4.png" />
                    <span>会员</span>
                    <p class="user_isvipspan">
                      {{ YS.toTime4($store.state.user.vip_expired_time) }}
                    </p>
                  </div>
                  <div class="user_tip_box" v-show="!isvip" data-type="unvip">
                    <img src="../assets/img/topa1.png" />
                    <span>非会员</span>
                  </div>
                  <div class="user_tip_box" data-type="center">
                    <img src="../assets/img/topa2.png" />
                    <span>个人中心</span>
                  </div>
                  <div class="user_tip_box" data-type="outlogin">
                    <img src="../assets/img/topa3.png" />
                    <span>退出登录</span>
                  </div>
                </div>
              </transition> -->
              <!-- 用户名下拉弹窗 -->
              <transition name="show">
                <div
                  class="user-tip"
                  :class="{ 'user-tip2': isvip }"
                  v-show="$store.state.showWindows.user && islogin"
                >
                  <div class="user_tip_top" :class="{ user_tip_top_vip: isvip }">
                    <img v-if="isvip" class="back-img-card" src="../assets/img/homecard_1.png" />

                    <div
                      class="user-tip-avaimg"
                      style="z-index: 1"
                      :class="{ 'scale-h': !$store.state.user.avatar }"
                    >
                      <img
                        :src="$store.state.user.avatar ? $store.state.user.avatar : ''"
                        :onerror="$store.state.defaulava"
                        height="48"
                        width="48"
                        alt="用户头像"
                      />
                    </div>
                    <div class="nameAtime">
                      <span class="user_tip_name" @click="cliUserDown($event, 'center')">
                        {{
                          $store.state.user.nickname
                            ? $store.state.user.nickname
                            : $store.state.user.username
                            ? $store.state.user.username
                            : $store.state.user.email
                        }}
                      </span>
                      <span class="user_tip_time2" v-show="!isvip">您还不是会员</span>
                      <span class="user_tip_time" v-show="isvip">
                        到期时间： {{ YS.toTime4($store.state.user.vip_expired_time) }}
                      </span>
                    </div>
                  </div>
                  <div class="user_tip_bottom">
                    <a @click="cliUserDown($event, 'center')">个人中心</a>
                    <div class="user_tip_right" @click="cliUserDown($event, 'outlogin')">
                      <div class="back_out_icon"></div>
                      <span>登出</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="suspension">
        <transition name="show_bootom_banner">
          <div class="sus-fir" v-show="floating_list">
            <div class="sus-top" @click="clifloating('top')">
              <!-- <img src="../assets/img/right-menu-1.png" /> -->
              <div class="sus-top1"></div>
            </div>
            <div class="sus-body">
              <div
                class="sus-list"
                @click="clifloating(item.symbol)"
                v-for="(item, index) in floating_list"
                :key="index"
              >
                <!-- v-show="item.symbol !== 'vip' || $store.state.islogin" -->
                <div
                  class="rm-1"
                  :class="{ lightcss: offLight && item.symbol == 'theme' }"
                  :style="{
                    'mask-image':
                      'url(' + (item.icon ? item.icon : require('../assets/img/rm-2-1.png')) + ')',
                  }"
                ></div>
                <div class="rm_mess">
                  <p>{{ item.title }}</p>
                </div>
              </div>
              <!-- <div class="sus-list sus-list1">
              <div class="rm-2-1"></div>
              <div class="rm_mess">
                <p>关灯</p>
              </div>
            </div>
            <div class="sus-list sus-list2 sus-vip" @click="YS.gotoVip($event, 'open', 'vipReg')">
              <div class="rm-3-1"></div>
              <div class="rm_mess">
                <p>充值vip</p>
              </div>
            </div>
            <div class="sus-list sus-list3">
              <div class="rm-6-1"></div>
              <div class="rm_mess">
                <p>求片</p>
              </div>
            </div>
            <div class="sus-list sus-list4" @click="YS.routetogo({ path: '/mhome' })">
              <div class="rm-4-1"></div>
              <div class="rm_mess">
                <p>移动端</p>
              </div>
            </div> -->
              <!-- <div class="sus-list sus-list5" @click="YS.toTop2">
              <div class="rm-5-1"></div>
            </div> -->
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <transition name="show">
      <div class="loginwin" v-show="$store.state.showlogin.loginReg">
        <!-- <img class="back-filter" src="../assets/img/getta3.png" /> -->
        <div class="login-logo"><img src="../assets/img/logo.png" alt="logo图标" /></div>
        <div class="login-a">
          <!-- <span>{{ logincode[$store.state.userStatus].title }}</span> -->
          <span></span>
        </div>
        <div id="loginIpu" class="login-body">
          <!-- 双标签头 -->
          <div
            class="login-tag"
            v-show="$store.state.userStatus != 'forget' && $store.state.userStatus != 'userLogin'"
          >
            <!-- 用户名注册 -->
            <div
              id="ltl"
              class="lt-l login-tag-act"
              @click="
                $store.commit('changeStatus', logincode[$store.state.userStatus].tagL), TOvercode()
              "
            >
              <span id="ltlspan">{{ logincode[$store.state.userStatus].tagL }}</span>
            </div>
            <!-- 展位线 -->
            <div class="lt-c"></div>
            <!-- 邮箱注册 -->
            <div
              id="ltr"
              class="lt-r"
              @click="$store.commit('changeStatus', logincode[$store.state.userStatus].tagR)"
            >
              <span id="ltrspan">{{ logincode[$store.state.userStatus].tagR }}</span>
            </div>
          </div>
          <!-- 单标签头 -->
          <div
            class="for-tag"
            v-show="$store.state.userStatus == 'forget' || $store.state.userStatus == 'userLogin'"
          >
            <div
              id="ltc"
              class="lt-cc"
              @click="$store.commit('changeStatus', logincode[$store.state.userStatus].tagC)"
            >
              <span id="ltcspan">{{ logincode[$store.state.userStatus].tagC }}</span>
            </div>
          </div>
          <!-- 账号密码输入框 -->
          <div class="lt-d">
            <input
              class="lt-input"
              v-model="registered.username"
              :placeholder="logincode[$store.state.userStatus].inpT"
            />
            <input
              class="lt-input"
              v-model="registered.password"
              :placeholder="logincode[$store.state.userStatus].inpB"
              type="password"
            />
            <div id="vercodeIpu" class="vercode-input">
              <input
                class="lt-input"
                maxlength="4"
                v-model="registered.captcha"
                placeholder="图形验证码"
                @keyup.enter="
                  $store.commit('changeStatus', logincode[$store.state.userStatus].butR);
                  TOsign();
                "
              />
              <a id="vercodea" class="vercode" @click="TOvercode">
                <img :src="verimg.url" alt="验证码图片" />
              </a>
            </div>
            <div id="codeIpu" class="code-input">
              <input
                tabindex="-1"
                class="lt-input"
                v-model="phrase_ver"
                maxlength="6"
                placeholder="请输入验证码"
                @keyup.enter="
                  $store.commit('changeStatus', logincode[$store.state.userStatus].butR);
                  TOsign();
                "
              />
              <a id="codea" class="varcode" @click="getmailver">
                {{ $store.state.code.count }}
              </a>
            </div>
          </div>
        </div>
        <span class="error_bottom">
          <transition name="mcenter_2">
            <span v-show="error_b">{{ errorM }}</span>
          </transition>
        </span>
        <!-- 登录/注册按钮 -->
        <div class="lt-f">
          <div
            class="ltf-l"
            @click="
              $store.commit('changeStatus', logincode[$store.state.userStatus].butL),
                TOvercode(),
                clearLoginInput()
            "
          >
            <span>{{ logincode[$store.state.userStatus].butL }}</span>
          </div>
          <div
            class="ltf-r"
            :class="{ cliloading: loadCli }"
            @click="
              $store.commit('changeStatus', logincode[$store.state.userStatus].butR);
              TOsign();
            "
          >
            <span v-show="!loadCli">{{ logincode[$store.state.userStatus].butR }}</span>
            <div class="dots-6" v-show="loadCli"></div>
          </div>
        </div>
        <!-- 忘记密码？ -->
        <div class="lt-g">
          <a
            class="forgetmess"
            @click="$store.commit('changeStatus', logincode[$store.state.userStatus].bot)"
          >
            {{ logincode[$store.state.userStatus].bot }}
          </a>
        </div>
        <!-- 用户协议 -->
        <div
          class="lt-protocol"
          v-show="$store.state.userStatus == 'userRes' || $store.state.userStatus == 'mailRes'"
        >
          <input type="checkbox" name="vehicle" v-model="protocol" />
          <span>我已阅读并同意</span>
          <a @click="getpageapi('service-terms', 'protocol')">《优视频用户协议》</a>
        </div>
        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'loginReg')"
          src="../assets/img/close.png"
          alt="关闭注册/登录弹窗"
        />
      </div>
    </transition>
    <!-- WIN 开通会员 -->
    <transition name="show">
      <div class="openVip" v-show="$store.state.showlogin.vipReg">
        <!-- 卡片头部top -->
        <!-- 这里根据op-unvip/op-isvip 判断是否vip的样式 -->
        <div class="op_top_dou">
          <div class="op-top-user">
            <!-- 头像 -->
            <div class="otu-img">
              <!-- <img src="../assets/img/avatar-2-64.png" /> -->
              <div
                class="otu_img1"
                :style="{
                  backgroundImage:
                    'url(' + ($store.state.user.avatar ? $store.state.user.avatar : userimg) + ')',
                }"
              ></div>
            </div>
          </div>
          <!-- 文字 -->
          <div class="op-top-text">
            <a>
              {{
                $store.state.user.nickname
                  ? $store.state.user.nickname
                  : $store.state.user.username
                  ? $store.state.user.username
                  : $store.state.user.email
              }}
            </a>

            <p class="vip_nodes">
              {{
                Date.parse(new Date()).toString().substr(0, 10) > $store.state.user.vip_expired_time
                  ? '您还不是会员'
                  : 'VIP剩余天数：' + YS.remainingTime($store.state.user.vip_expired_time)
              }}
            </p>
            <span @click="YS.routetogo({ path: '/privileges' })" class="win_vipcan">
              <img class="win_vipcan-icon" src="../assets/img/vipcan.png" />
              <span>VIP特权</span>
            </span>
            <span @click="YS.routetogo({ path: '/getta' })" class="win_tavip">
              <img class="win_vipcan-icon" src="../assets/img/vipcan.png" />
              <span>为Ta开通VIP</span>
            </span>
            <!-- <p class="vip_nodes">您还不是会员</p> -->
          </div>
          <img
            class="vip_close"
            @click="YS.gotoVip($event, 'open', 'confirn')"
            src="../assets/img/vip_close_double.png"
            alt="关闭开通会员弹窗"
          />
        </div>
        <!-- 支付套餐主体 -->
        <div class="pay_body">
          <!-- 选择支付时长 -->
          <div class="pay_body_box">
            <!-- 选择支付方式 -->
            <!-- <div class="pb_top">
              <span>选择支付时长</span>
            </div> -->
            <div class="pb_body pb_center" v-if="payList">
              <!-- 套餐遍历 -->
              <div
                class="pb_list"
                :class="{ pb_act: pay_index.top == index }"
                v-for="(item, index) in payList"
                :key="index"
                @click="payselect(item.id, index, item.promotion_id)"
              >
                <p class="pbl_type">{{ item.name }}</p>
                <div
                  class="pbl_dou_box"
                  :class="{
                    'pbl_dou_box-2': item.level == '6',
                    'pbl_dou_box-5': item.level == '7',
                  }"
                >
                  <p class="pbl_date">
                    {{ item.period }}天
                    <span class="pbl_addday" v-show="item.add_days">+{{ item.add_days }}天</span>
                  </p>
                  <p class="pbl_price" v-show="item.promotion_id">
                    {{ item.price }}&nbsp;{{ item.currency }}
                  </p>
                  <div class="pbl_type2">
                    <p class="pbl_money" v-show="item.promotion_id">
                      {{ item.discounted_price }}
                    </p>
                    <p class="pbl_money" v-show="!item.promotion_id">
                      {{ item.price }}
                    </p>
                    <span class="pbl_currency">{{ item.currency }}</span>
                  </div>
                  <!-- <p class="pbl_money">
                  {{ item.price }}
                  <span class="pbl_currency">{{ item.currency }}</span>
                </p> -->
                  <div class="pbl_bottom-heng"></div>
                  <img
                    class="pb_list_img"
                    src="../assets/img/vip-logo.png"
                    alt="选择支付背景卡片"
                  />
                  <img
                    v-if="item.level == '6'"
                    class="pb_list_img-2ren"
                    src="../assets/img/2ren.png"
                  />
                  <img
                    v-else-if="item.level == '7'"
                    class="pb_list_img-2ren"
                    src="../assets/img/5ren.png"
                  />
                  <img
                    v-else-if="item.period == 365 && item.level != '6' && item.level != '7'"
                    class="pb_list_img-2ren"
                    src="../assets/img/chaohuasuan.png"
                  />
                </div>
              </div>
            </div>
            <div class="pb_body_Bwhitre"></div>
          </div>
          <!-- 选择支付类型 -->
          <div class="pay_type">
            <!-- 选择支付方式 -->
            <div class="pb_top">
              <span>选择支付方式</span>
            </div>
            <div class="pb_body">
              <!-- 套餐遍历 -->
              <div
                class="paytype_list"
                :class="{ pb_act_web: pay_index.bottom == index }"
                v-for="(item, index) in payType"
                :key="index"
                @click="payselect2(item, index)"
              >
                <img
                  class="paytype_img"
                  :src="item.icon || payicon[item.symbol]"
                  :alt="item.name"
                />
              </div>
            </div>
          </div>

          <!-- 会员特权 -->
          <div class="pay_cando">
            <div class="pb_top vip_can">
              <img class="mvipcanimg" src="../assets/img/mvipcan.png" alt="会员特权介绍" />
              <span class="vip_can_title">会员特权介绍</span>
            </div>
            <div class="vip_can_body">
              <div class="mvip_clist">
                <img src="../assets/img/mv1.png" alt="清/蓝光清晰度" />
                <span>清/蓝光清晰度</span>
              </div>
              <div class="mvip_clist">
                <img src="../assets/img/mv2.png" alt="倍数播放" />
                <span>倍数播放</span>
              </div>
              <div class="mvip_clist">
                <img src="../assets/img/mv3.png" alt="VIP会员独享片源" />
                <span>VIP会员独享片源</span>
              </div>
              <div class="mvip_clist">
                <img src="../assets/img/mv4.png" alt="跳过片头/片尾" />
                <span>跳过片头/片尾</span>
              </div>
              <div class="mvip_clist">
                <img src="../assets/img/mv5.png" alt="过滤广告" />
                <span>过滤广告</span>
              </div>
              <div class="mvip_clist">
                <img src="../assets/img/mv6.png" alt="VIP会员标识" />
                <span>VIP会员标识</span>
              </div>
            </div>
          </div>
        </div>
        <div class="yesIpay">
          <span class="conbutton_buy_2" :class="{ conbutton_buy_none: payCli }" @click="gopay()">
            <span v-show="!payCli">确认支付</span>
            <div class="dots-6" v-show="payCli"></div>
          </span>
        </div>
        <!-- VIP会员服务条款 -->
        <div class="pay_terms">
          <span>充值即代表您已阅读并同意</span>
          <a @click="getpageapi('recharge-agrrement', 'terms')">《VIP会员服务条款》</a>
        </div>
      </div>
    </transition>
    <!-- WIN VIP会员协议条款 -->
    <transition name="show">
      <div v-if="$store.state.showlogin.terms">
        <div class="protocol_win">
          <div class="protocol_body">
            <a class="pro_title">{{ getpage.title }}</a>

            <span v-html="getpage.content"></span>
            <a class="pro_button" @click="YS.gotoVip($event, 'close', 'terms')">我知道了</a>
          </div>
          <img
            class="login-close"
            @click="YS.gotoVip($event, 'close', 'terms')"
            src="../assets/img/close.png"
            alt="关闭VIP会员协议条款弹窗"
          />
        </div>
      </div>
    </transition>
    <!-- WIN 用户协议条款 -->
    <transition name="show">
      <div v-if="$store.state.showlogin.protocol">
        <div class="protocol_win">
          <div class="protocol_body">
            <a class="pro_title">{{ getpage.title }}</a>
            <pre><span v-html="getpage.content"></span></pre>
            <a class="pro_button" @click="YS.gotoVip($event, 'close', 'protocol')">我知道了</a>
          </div>
          <img
            class="login-close"
            @click="YS.gotoVip($event, 'close', 'protocol')"
            src="../assets/img/close.png"
            alt="关闭用户协议条款弹窗"
          />
        </div>
      </div>
    </transition>
    <!-- WIN 确认关闭VIP购买 -->
    <transition name="show">
      <div class="confirn_vip" v-show="$store.state.showlogin.confirn">
        <div class="confirn_mess">
          <img src="../assets/img/leave_vip.png" alt="确认警告图片" />
          <span class="confirn_mess_title">真的要放弃吗？</span>
          <p class="confirn_mess_nodes">VIP可享受高清、免广告等特权</p>
        </div>
        <div class="confirn_buttons">
          <a
            class="conbutton_leave"
            @click="
              YS.gotoVip($event, 'close', 'confirn');
              YS.gotoVip($event, 'close', 'vipReg');
            "
          >
            狠心离开
          </a>
          <a class="conbutton_buy" @click="YS.gotoVip($event, 'close', 'confirn')">立即购买</a>
        </div>
        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'confirn')"
          src="../assets/img/close.png"
          alt="关闭确认购买弹窗"
        />
      </div>
    </transition>

    <!-- WIN 确认退出登录 -->
    <transition name="show">
      <div class="confirn_vip" v-show="$store.state.showlogin.outlogin">
        <div class="confirn_mess">
          <img src="../assets/img/leave_vip.png" alt="退出登录警告图片" />
          <!-- <span class="confirn_mess_title">是否需要退出登录？</span> -->
          <p class="confirn_mess_nodes padtop0">是否需要退出登录？</p>
        </div>
        <div class="confirn_buttons">
          <a class="conbutton_leave" @click="toOutlogin()">确定</a>
          <a class="conbutton_buy" @click="YS.gotoVip($event, 'close', 'outlogin')">取消</a>
        </div>
        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'outlogin')"
          src="../assets/img/close.png"
          alt="关闭确认退出弹窗弹窗"
        />
      </div>
    </transition>
    <!-- WIN 查看支付结果 -->
    <transition name="show">
      <div class="payresult" v-if="$store.state.showlogin.payresult">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="查看支付结果背景"
        />
        <div class="payrbody15">
          <span>检测到您24小时内已成功付款了一笔订单</span>
          <span>
            订单时间({{ new Date().toString().split('(')[1].split(')')[0] }})：{{
              YS.toTime(pay_ok)
            }}
          </span>
          <img src="../assets/img/windef.png" height="150" width="253" alt="支付遇到问题背景" />
        </div>
        <div class="payrbodyflex">
          <a class="payb_1" @click="YS.gotoVip($event, 'close', 'payresult')">仍要充值</a>
          <a
            class="payb_2"
            @click="YS.routetogo({ path: '/recharge', query: { go: 'paySecond' } })"
          >
            查看支付结果
          </a>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'payresult')"
          src="../assets/img/close.png"
          alt="关闭查看支付结果"
        />
      </div>
    </transition>
    <!-- WIN 卡密充值会员弹窗 -->
    <transition name="show">
      <div class="vipcardWIN" v-if="$store.state.showlogin.vipcardWIN">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="logo图标"
        />
        <div class="payrbody13">
          <a>请输入卡密：</a>
        </div>
        <div class="payrbody14">
          <input v-model="vipcardact" @keyup.enter="vipcardcli" />
          <a @click="vipcardcli">充值</a>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'vipcardWIN')"
          src="../assets/img/close.png"
          alt="关闭卡密充值弹窗"
        />
      </div>
    </transition>
    <!-- WIN 公用成功弹窗 -->
    <transition name="show">
      <div class="vipcardWIN" v-show="$store.state.showlogin.success">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="logo图标"
        />
        <div class="payrbody15">
          <span>{{ publicSuccess.title }}</span>
          <img src="../assets/img/winsuccess.png" height="150" width="253" alt="成功提示图片" />
        </div>
        <div class="payrbody14">
          <a @click="pubSucButton">确认</a>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'success')"
          src="../assets/img/close.png"
          alt="关闭公用成功弹窗"
        />
      </div>
    </transition>
    <!-- WIN 公用失败弹窗 -->
    <transition name="show">
      <div class="vipcardWIN" v-show="$store.state.showlogin.failure">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="logo图片"
        />
        <div class="payrbody15">
          <span class="pubfail">{{ publicFailure.title }}</span>
          <img src="../assets/img/winfailure.png" height="150" width="253" alt="失败提示图片" />
        </div>
        <div class="payrbody14">
          <a @click="publicButton()">
            {{ publicFailure.failbutton ? publicFailure.failbutton : '返回重试' }}
          </a>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'failure')"
          src="../assets/img/close.png"
          alt="关闭失败弹窗"
        />
      </div>
    </transition>
    <!-- WIN 公用重复获取 -->
    <transition name="show">
      <div class="vipcardWIN" v-if="$store.state.showlogin.payres">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="logo图片"
        />
        <div class="payrbody15">
          <span class="loadspan0">{{ pay_test }}</span>
          <span class="loadspan">{{ geiviptime }}秒后获取会员状态，请勿关闭此窗口。</span>
          <img
            class="load0"
            src="../assets/img/loading.png"
            height="100"
            width="100"
            alt="加载图片"
          />
        </div>
        <div class="payrbody14">
          <!-- <span class="loadcount" v-show="geivipcount">已获取{{ geivipcount }}次！</span> -->
          <!-- <a @click="pubSucButton">确认</a> -->
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'payres')"
          src="../assets/img/close.png"
          alt="关闭重复获取会员状态弹窗"
        />
      </div>
    </transition>
    <!-- WIN 联系客服弹窗 -->
    <transition name="show">
      <div class="vipcardWIN" v-if="$store.state.showlogin.kefugo">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="logo图片"
        />
        <img
          v-if="kefuimg"
          class="kefugo_img"
          :src="kefuimg"
          width="200"
          height="200"
          alt="客服联系二维码"
        />
        <span v-if="kefuimg" class="b_g_11">👆 【微信】扫一扫添加 👆</span>
        <a target="_blank" :href="`https://t.me/${telegram}`" class="tele-div" v-if="telegram">
          <img src="../assets/img/telegram128.png" />
          <span>@{{ telegram }}</span>
        </a>
        <span class="b_g_22">在线时间：{{ timeText }}</span>
        <span class="b_g_22">{{ timeArea }}</span>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'kefugo')"
          src="../assets/img/close.png"
          alt="关闭联系客服弹窗"
        />
      </div>
    </transition>
    <!-- WIN 播放页报错弹窗 -->
    <transition name="show">
      <div class="playerror" v-if="$store.state.showlogin.playerror">
        <div class="payrbody">
          <a>视频还在尝试加载中，您还可以向我们反馈：</a>
        </div>
        <div class="payrbody5">
          <textarea
            maxlength="255"
            v-model="playerrorText"
            placeholder="请描述您遇到的问题（可留空）"
          ></textarea>
          <div class="errordiv">
            <span class="error_wait" @click="YS.gotoVip($event, 'close', 'playerror')">
              继续等待
            </span>
            <a @click="cliplayerror" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">提交</span>
              <div class="dots-6" v-show="loadCli"></div>
            </a>
          </div>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'playerror')"
          src="../assets/img/close.png"
          alt="关闭播放页报错弹窗"
        />
      </div>
    </transition>
    <!-- WIN 是否清空所有信息 小弹窗 -->
    <transition name="show">
      <div class="small" v-if="$store.state.showlogin.small">
        <div class="smalltitle">
          <a v-if="smalltype == 'edit'">是否删除所选的播放记录</a>
          <a v-else-if="smalltype == 'favEdit'">是否删除所选的收藏资源</a>
          <a v-else>是否删除所有信息</a>
        </div>
        <div class="smallbody">
          <span class="smallbody-a" @click="YS.gotoVip({}, 'close', 'small')">取消</span>
          <span class="smallbody-b" @click="smallbutton">确定</span>
        </div>
      </div>
    </transition>
    <transition name="show">
      <div class="mess_top" v-show="mess_show">
        {{ messTop }}
      </div>
    </transition>
    <!-- 遮罩层 -->
    <div id="cover"></div>
    <!-- z-index=200的遮罩层 -->
    <div id="coverMax"></div>
    <!-- z-index=300的遮罩层 -->
    <div id="coverMax300"></div>
    <!-- <div id="shadow" :class="{ shadow: offLight }"></div> -->
  </div>
</template>
<script>
let that;
let vuex;
let fetch;
export default {
  // inject: ['routerRefresh'], //在子组件中注入在父组件中创建的属性
  name: 'Top',
  data() {
    return {
      searchTime: true, //自动联想关键词间隔时间
      timeText: '', //客服在线时间
      timeArea: '', //客服时间时区
      lastSeachKeyword: '',
      similarShow: false,
      pay_test: '支付成功！',
      pay_ok: '',
      focusInput: false,
      mess_show: false,
      messTop: '', //消息提示
      errorM: '', //错误提示文本
      error_b: false, //错误提示开关
      payCli: false,
      JTstr: '', //简体
      loadCli: false, //点击按钮
      wuyeCode: 1, //判断是否隐藏版中间值
      vipOldtime: null,
      vipNewtime: null,
      geivipcount: 0,
      geiviptime: 0,
      menuVice: [],
      // menu_dist: [],
      menutotal: [],
      nowsymbol: '',
      kefuimg: '', //联系客服二维码
      telegram: '',
      message_total: null, //系统消息总数
      smalltype: '', //使用小弹窗的类型
      vicetop: 0, //导航菜单二级菜单高度
      floating_list: null, //悬浮窗数据
      payList: [],
      payType: [],
      tophis: true,
      topwatch: true,
      viceshow: false, //副导航
      vipcardact: '', //vip点卡输入框
      winScrollTop: null, //
      clientHeight: null, //获取浏览器可视区域高度
      getpage: {}, //通过标识取页面内容
      playerrorText: '', //视频报错反馈文本框
      searchkeyword: '', //搜索框
      customer_service_list: [], //客服信息
      similarList: [], //搜索词联想关键词
      isNight: window?.g?.isNight ? true : false,
      publicSuccess: {
        titie: '',
        type: '',
        bottomSpan: '',
      },
      publicFailure: {
        titie: '',
        type: '',
        bottomSpan: '',
        failbutton: '返回重试',
      },
      publiMustLogin: {
        titie: '',
        type: '',
        bottomSpan: '',
        failbutton: '前往登录',
      },
      pages: {
        page: 1,
        pagesize: 15,
      },
      userimg: require('@/assets/img/avatar1-32.png'),
      //图像验证码
      verimg: {
        url: '',
        id: '',
      },
      Menuindex: null, //主菜单的选择下标
      phrase_id: '',
      phrase_ver: '',
      registered: {
        username: '',
        password: '',
        captcha: '',
        phrase_id: '',
      },
      protocol: true, //用户协议
      // paymeallist: {},
      cover: {},
      show: true,
      count: '验证码',
      timer: null,
      verCode: {
        discode: false,
        dis: '验证码',
        countdown: 60,
        text: '验证码',
      },
      logincode: {
        userLogin: {
          title: '登录优视频',
          tagL: '用户名登录',
          tagR: '邮箱登录',
          tagC: '用户名/邮箱登录',
          inpT: '请输入用户名/邮箱',
          inpB: '请输入密码',
          butL: '注册账号',
          butR: '立即登录',
          bot: '忘记密码？',
        }, //用户登录
        mailLogin: {
          title: '登录优视频',
          tagL: '用户名登录',
          tagR: '邮箱登录',
          tagC: false,
          inpT: '请输入邮箱地址',
          inpB: '请输入密码',
          butL: '注册账号',
          butR: '立即登录',
          bot: '忘记密码？',
        }, //邮箱登录
        userRes: {
          title: '注册优视频',
          tagL: '用户名注册',
          tagR: '邮箱注册',
          tagC: false,
          inpT: '请输入用户名',
          inpB: '请输入密码',
          butL: '返回登录',
          butR: '立即注册',
          bot: '忘记密码？',
        }, //用户注册
        mailRes: {
          title: '注册优视频',
          tagL: '用户名注册',
          tagR: '邮箱注册',
          tagC: false,
          inpT: '请输入邮箱',
          inpB: '请输入密码',
          butL: '返回登录',
          butR: '立即注册',
          bot: '忘记密码？',
        }, //邮箱注册
        forget: {
          title: '忘记密码',
          tagL: '用户名注册',
          tagR: '邮箱注册',
          tagC: '邮箱重置密码',
          inpT: '请输入邮箱',
          inpB: '请输入新密码',
          butL: '返回登录',
          butR: '立即重置',
          bot: '账号注册',
        }, //忘记密码
      },
      watchRec: [
        { name: '山河令', num: '8', time: '14秒前' },
        { name: '乡村爱情18', num: '55', time: '14天前' },
        { name: '白蛇情节', num: '1', time: '1个月前' },
        { name: '一个天使的传说', num: '2', time: '14分支前' },
        { name: '我是大天使', num: '-', time: '1小时前' },
        { name: '我们都拥抱在一起', num: '-', time: '14秒前' },
        { name: '动作电影', num: '1', time: '14秒前' },
        { name: '山河令213', num: '3', time: '14秒前' },
      ],
      searchTop: [],
      keywork_list: [],

      payicon: {
        paypal: require('../assets/img/pay1.png'),
        alipay: require('../assets/img/pay2.png'),
        wechat: require('../assets/img/pay3.png'),
        'vip-card': require('../assets/img/pay4.png'),
        artificial: require('../assets/img/pay5.png'),
      },
      pay_index: {
        top: 0,
        bottom: null,
      },
      //支付接口的参数
      gotoPay: {
        vip_card_category_id: '',
        payment_method_id: '',
        platform: 1,
      },
      //支付接口返回数据
      rightnowPay: {
        payment_order_id: null,
        redirect_url: '',
      },
      icon: {
        before: '../assets/img/down_white',
      },
      thiswhite: this.white,
      childURL: window.g,
    };
  },
  props: ['white'],
  computed: {
    // //悬浮窗
    // floating_list: function () {
    //   return this.$store.state.floating_list;
    // },
    //开关灯
    offLight: function () {
      return this.$store.state.offLight;
    },
    // //支付
    // payList: function () {
    //   return this.$store.state.payList;
    // },
    // //支付
    // payType: function () {
    //   return this.$store.state.payType;
    // },
    //播放历史
    playList: function () {
      return that.$store.state.islogin
        ? this.$store.state.playbody.playList
        : localStorage.getItem('not_logged_history')
        ? JSON.parse(localStorage.getItem('not_logged_history'))
        : null;
    },
    isvip: () => {
      return that.$store.state.isvip;
    },
    islogin: () => {
      return that.$store.state.islogin;
    },
    //二级菜单二级导航
    // menuVice: {
    //   // get: () => {
    //   //   if (that.viceshow) {
    //   //     return that.$store.state.menuVice;
    //   //   }
    //   // },
    //   // set: (newValue) => {
    //   //   debugger;
    //   //   console.log(newValue);
    //   //   if (that.viceshow) {
    //   //     let vicehei = document.getElementsByClassName('vice')[0];
    //   //     console.log(vicehei.scrollHeight);
    //   //   }
    //   // },
    // },
  },
  watch: {
    searchkeyword() {
      //第一个判断条件筛选空格
      //第二个判断筛选有效字段后加空格，和避免上次重复搜索关键词
      if (
        that.searchTime &&
        that.searchkeyword &&
        that.searchkeyword.replace(/\s+/g, '') &&
        that.lastSeachKeyword != that.searchkeyword.replace(/\s+/g, '')
      ) {
        that.searchTime = false;
        that.getSimilar();
        that.lastSeachKeyword = that.searchkeyword.replace(/\s+/g, '');
        setTimeout(() => {
          that.searchTime = 1500;
        }, 1);
      }
    },
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;

    if (localStorage.getItem('pay_ok')) {
      if (localStorage.getItem('pay_ok').length >= 13) {
        localStorage.removeItem('pay_ok');
      }
    }
    // that.paymeallist = document.getElementsByClassName('pb_list');
    document.addEventListener('click', function (e) {
      // 判断被点击的元素是不是scheduleInput元素，不是的话，就隐藏之
      if (e.target !== that.noneMap) {
        for (let v in vuex.showWindows) {
          that.$store.commit('changeEvery', { key: 'showWindows', value: false, key2: v });
          // vuex.showWindows[v] = false;
        }
      }
    });
    that.$store.commit('getloghtBool');
    // var app3 = document.getElementById('app3');
    // if (that.offLight) {
    //   app3.classList.add('app_offLight');
    // } else {
    //   if (app3.classList) {
    //     app3.classList.remove('app_offLight');
    //   }
    // }
    try {
      let timeCha = -(new Date().getTimezoneOffset() / 60);
      // debugger;
      let stratTime = that.$store.state.onlineTime.startTime + timeCha;
      let endTime = that.$store.state.onlineTime.endTime + timeCha;
      let countNum = 0;
      while (stratTime < 0 && countNum < 10) {
        stratTime += 24;
        countNum += 1;
      }
      while (stratTime >= 24 && countNum < 10) {
        stratTime -= 24;
        countNum += 1;
      }
      while (endTime < 0 && countNum < 10) {
        endTime += 24;
        countNum += 1;
      }
      while (endTime > 24 && countNum < 10) {
        endTime -= 24;
        countNum += 1;
      }
      let string_stratTime = stratTime.toString();
      let string_endTime = endTime.toString();
      if (string_stratTime.length < 2) string_stratTime = '0' + string_stratTime;
      if (string_endTime.length < 2) string_endTime = '0' + string_endTime;
      that.timeText = string_stratTime + ':00-' + string_endTime + ':00';
    } catch (error) {
      console.log(error.message);
    }
    try {
      let linshiArea = new Date().toString().split('(')[1].split(')')[0];
      that.timeArea = linshiArea;
    } catch (error) {
      console.log(error.message);
    }
  },
  beforeMount() {},
  async mounted() {
    window.loginError = that.loginError;
    //获取菜单存储
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        //that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotal = menutotalstore.data;
      }
    }
    that.YS.checklight(); //开灯关灯初始化
    that.$store.commit('changelogin');
    that.$store.commit('getisvipCode');
    // 当为登录状态时
    if (that.$store.state.islogin) {
      that.getPlaylist(); //获取播放历史记录列表
      that.getmesslist(); // 获取通知消息列表
    }

    //获取支付缓存
    var payListstore = JSON.parse(localStorage.getItem('payList'));
    if (payListstore) {
      if (payListstore.time > new Date().getTime()) {
        that.payList = payListstore.data;
      }
    }
    var payTypestore = JSON.parse(localStorage.getItem('payType'));
    if (payTypestore) {
      if (payTypestore.time > new Date().getTime()) {
        that.payType = payTypestore.data;
      }
    }
    //获取悬浮窗缓存
    var floattore = JSON.parse(localStorage.getItem('floating_list'));
    if (floattore) {
      if (floattore.time > new Date().getTime()) {
        that.floating_list = floattore.data;
      }
    }

    //获取菜单栏
    if (!that.menutotal?.[0]) {
      try {
        let data = await fetch.api_categoryhome({});
        // 如果是午夜版-区分
        if (window.g.isNight) {
          const dataTotal = data.category_list.find((item) => item.symbol === 'night');

          data.category_list &&
            data.category_list.forEach((e) => {
              that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
              if (e.pid == dataTotal.id) {
                that.menutotal.push(e);
              }
            });
          localStorage.setItem('nightData', JSON.stringify(dataTotal));
        } else {
          // console.log(data);
          data.category_list &&
            data.category_list.forEach((e) => {
              that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });

              if (e.pid == 0) {
                e.list = [];
                that.menutotal.push(e);
              } else {
                that.menutotal.forEach((a, i) => {
                  if (a.id == e.pid) {
                    that.menutotal[i].list.push(e);
                  }
                });
              }
            });
        }
        //存储至本地
        that.YS.savestore('menutotal', that.menutotal, 600);
      } catch (error) {
        console.log(error);
      }
    }
    that.getSearchinput();
    if (that.$parent.getRL) {
      that.$parent.getRL();
    }
    that.searchTop = localStorage.getItem('topsearchword')
      ? JSON.parse(localStorage.getItem('topsearchword'))
      : [];

    that.floating(); //获取首页悬浮菜单内容
  },
  methods: {
    loginError() {
      that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
      that.TOvercode(); //获取验证码
      that.$store.commit('changelogin');
      that.$store.commit('getisvipCode');
    },
    focusSearch(type) {
      that.focusInput = type;
    },
    //获取联想搜索词
    async getSimilar() {
      that.similarShow = false;
      try {
        if (window.g.isNight) {
          return;
        } else {
          let data = await fetch.api_videosuggest({ keyword: that.searchkeyword });
          if (!data.suggest_list) return;
          that.similarList = data.suggest_list;
          if (data.suggest_list.length) {
            that.similarShow = true;
          }
        }
      } catch (error) {
        that.similarShow = true;
        console.log(error.message);
      }
    },
    //公用成功弹窗确定按钮
    pubSucButton() {
      switch (that.publicSuccess.type) {
        case 'vip-card':
          that.YS.gotoVip({}, 'close', 'vipcardWIN');
          that.YS.gotoVip({}, 'close', 'vipReg');
          break;
        case 'recharge':
          that.YS.routetogo({ path: '/recharge' });
          break;

        default:
          break;
      }
      that.YS.gotoVip({}, 'close', 'success');
    },
    //点击logo
    clilogo() {
      that.thiswhite = that.$store.state.model.white;
      // document.getElementById('topNav').classList.remove('top-nav-white');
      if (that.$route.path == '/') {
        that.YS.toTop2();
      } else {
        // that.YS.routetogo({ path: '/' });
        window.location.href = '/';
      }
    },
    // 点击副导航栏
    clivice(id, pid, index) {
      //当时是筛选页时
      if (that.$route.path == '/fil') {
        that.$parent.filact.parent_category_id = id;
        that.$parent.filact.category_id = pid;
        that.$parent.menu_fu_act = index;
        that.$parent.menu_act = that.Menuindex;
        that.$parent.getrou();
      } else {
        //不是筛选页直接跳转
        that.YS.routetogo({
          path: '/fil',
          query: { parent_category_id: pid, category_id: id },
        });
      }

      // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    //点击顶级分类
    clitip(id, pid, index) {
      //当是筛选页时
      if (that.$route.path == '/fil') {
        that.$parent.changerFilter(index + 1, id);
      } else {
        if (window.g.isNight) {
          that.YS.routetogo({
            path: '/fil',
            query: { category_id: id },
          });
        } else {
          if (Number(id) === 107) {
            window.open(window.g.CHILD_WEB_RUL);
          } else {
            //不是筛选页直接跳转
            that.YS.routetogo({
              path: '/fil',
              query: { parent_category_id: id },
            });
          }
        }
      }

      // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    returnClitip() {},
    //清空历史搜索
    clearsearch() {
      that.searchTop = [];
      localStorage.setItem('topsearchword', []);
      // that.smalltype = 'search';
      // that.YS.gotoVip({}, 'open', 'small');
    },
    //点击搜索
    Clisearch(key) {
      if (key) that.searchkeyword = key;

      //转换成简体
      that.JTstr = '';
      for (var i = 0; i < that.searchkeyword.length; i++) {
        if (that.YS.ftPYStr().indexOf(that.searchkeyword.charAt(i)) != -1)
          that.JTstr += that.YS.simpPYStr().charAt(
            that.YS.ftPYStr().indexOf(that.searchkeyword.charAt(i)),
          );
        else if (that.YS.qqPYStr().indexOf(that.searchkeyword.charAt(i)) != -1)
          that.JTstr += that.YS.simpPYStr().charAt(
            that.YS.qqPYStr().indexOf(that.searchkeyword.charAt(i)),
          );
        else that.JTstr += that.searchkeyword.charAt(i);
      }
      that.searchkeyword = that.JTstr;
      //如果访问到父组件的方法则直接调用，没有的话直接跳转
      if (that.$parent.filact) {
        that.$parent.filact.keyword = that.searchkeyword;

        // //判断是否隐藏版
        // that.wuyeCode = 1;
        // that.menutotal.forEach((e) => {
        //   if (e.id == that.$parent.filact.parent_category_id) {
        //     that.wuyeCode = e.show;
        //   }
        // });
        // debugger;
        //如果是隐藏版
        if (window.g.isNight) {
          let nightData = null;
          let nightID = '';
          if (localStorage.getItem('nightData')) {
            nightData = localStorage.getItem('nightData');
          }
          if (nightData) {
            nightID = JSON.parse(nightData).id; //默认null
          } else {
            nightID = that.menutotal[0].pid; //默认null
          }
          // that.$parent.filact;
          that.YS.routetogo({
            path: '/fil',
            query: {
              parent_category_id: nightID,
              keyword: that.$parent.filact.keyword,
            },
          });
        } else {
          that.YS.routetogo({
            path: '/fil',
            query: { keyword: key || that.searchkeyword },
          });
        }
        // that.$parent.getrou();
      } else {
        if (window.g.isNight) {
          let nightData = null;
          let nightID = '';
          if (localStorage.getItem('nightData')) {
            nightData = localStorage.getItem('nightData');
          }
          if (nightData) {
            nightID = JSON.parse(nightData).id; //默认null
          } else {
            nightID = that.menutotal[0].pid; //默认null
          }
          that.YS.routetogo({
            path: '/fil',
            query: {
              keyword: key || that.searchkeyword,
              parent_category_id: nightID ? nightID : '',
            },
          });
        } else {
          that.YS.routetogo({
            path: '/fil',
            query: { keyword: key || that.searchkeyword },
          });
        }
      }

      if (that.searchkeyword) {
        var a = that.searchTop;
        if (a.indexOf(that.searchkeyword) === -1) {
          a.unshift(that.searchkeyword);
          that.searchTop = a.slice(0, 4);
          localStorage.setItem('topsearchword', JSON.stringify(that.searchTop.slice(0, 4)));
        } else if (a.indexOf(that.searchkeyword) >= 0) {
          //当搜索的值存在搜索列表时
          that.searchTop = [];
          var num1 = a[a.indexOf(that.searchkeyword)]; //准备放到第一位
          a[a.indexOf(that.searchkeyword)] = null;
          a.forEach((e) => {
            !!e && that.searchTop.push(e);
          });
          that.searchTop.unshift(num1);
        }
      }
      if (this.$parent.getSearch) {
        this.$parent.getSearch();
      }
      that.YS.toTop2();
      // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    //user点击
    clickUser() {
      that.$store.commit('changeStatus', '用户名登录');
      if (vuex.islogin == true) {
        that.YS.routetogo({ path: '/history' });
        // window.location.href = 'history';
      } else {
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.TOvercode(); //获取验证码
      }
    },
    // 导航左菜单hover/点击事件
    goVice(e, index, noneShow) {
      that.viceshow = true;
      // console.log(e.layerY);
      // console.log(e.pageY);
      that.vicetop = document.getElementsByClassName('frame-item')[index].offsetTop - 12;
      // let winhei = document.documentElement.clientHeight || document.body.clientHeight;

      e.stopPropagation();
      // vuex.menuVice = vuex.menutotal[index].list;
      that.Menuindex = index + 1;
      // that.$store.commit('changeMenuVice', index);
      if (noneShow) {
        that.menuVice = [];
        that.viceshow = false;
        that.$store.commit('changeEvery', { key: 'showWindows', value: false, key2: 'navVice' });
      } else {
        that.menuVice = that.menutotal[index].list;
        that.$store.commit('changeEvery', { key: 'showWindows', value: true, key2: 'navVice' });
      }
      // vuex.showWindows.navVice = true;
    },
    search(e) {
      e.stopPropagation();
    },
    //选择点卡
    payselect(id, i) {
      that.gotoPay.vip_card_category_id = id;
      that.pay_index.top = i; //当前选择的css下标
      // that.YS.actlist(that.paymeallist, i, 'pb_act');
    },
    noticetagChange(e, i) {
      var obj = document.getElementsByClassName('ntt');
      that.YS.actlist(obj, i, 'notice-act');
    },
    //获取验证码
    async TOvercode() {
      try {
        let data = await fetch.api_verCode({});
        that.verimg.url = 'data:image/jpeg;base64,' + data.captcha_data;
        that.registered.phrase_id = data.phrase_id;
      } catch (error) {
        console.log(error.message);
      }
    },
    // TOvercode1() {
    //   fetch.api_verCode({}).then((data) => {
    //     // console.log(data);
    //     that.verimg.url = 'data:image/jpeg;base64,' + data.captcha_data;
    //     that.registered.phrase_id = data.phrase_id;
    //   });
    // },

    //注册/登录
    async TOsign() {
      that.error_b = false;
      if (that.loadCli) return false;
      that.loadCli = true;

      //普通注册
      if (vuex.userStatus == 'userRes') {
        try {
          await that.YS.checkUser(
            that.registered.username,
            that.registered.password,
            that.registered.captcha,
          );
        } catch (error) {
          that.errorM = error?.message;
          setTimeout(() => {
            that.error_b = true;
            that.loadCli = false;
          }, 300);

          return false;
        }
        // if (that.registered.username.length > 10) {
        //   that.errorM = '用户名长度太长';
        //   that.error_b = true;
        //   // alert('用户名长度太长');
        //   that.TOvercode(); //获取验证码
        //   that.loadCli = false;
        //   return false;
        // }
        // var usern = /^[a-zA-Z0-9_-]{1,}$/;
        // if (!that.registered.username.match(usern)) {
        //   that.errorM = '用户名只能由字母数字下划线横杠组成';
        //   that.error_b = true;
        //   // alert('用户名只能由字母数字下划线横杠组成');
        //   that.TOvercode(); //获取验证码
        //   that.loadCli = false;
        //   return false;
        // }
        try {
          var data = await that.api.user.api_registered(that.registered);
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
          that.YS.gotoVip(0, 'close', 'loginReg');
          that.usepubilDialog({ title: '注册成功', type: 'vip-card', code: true });
          that.$store.commit('changelogin');
        } catch (error) {
          that.TOvercode(); //获取验证码
          // alert(error.message);
          that.errorM = error.message;
          that.error_b = true;
          that.registered.captcha = '';
        } finally {
          that.loadCli = false;
        }
      }
      //邮箱注册
      else if (vuex.userStatus == 'mailRes') {
        let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!reg.test(that.registered.username)) {
          // alert('邮箱格式不正确');
          that.errorM = '邮箱格式不正确';
          that.error_b = true;
          that.TOvercode(); //获取验证码
          that.loadCli = false;
          return false;
        }
        try {
          let data = await fetch.api_passportsignupByEmail({
            email: that.registered.username,
            password: that.registered.password,
            captcha: that.phrase_ver,
            phrase_id: that.phrase_id,
          });
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
          // alert('注册成功');
          that.YS.gotoVip(0, 'close', 'loginReg');
          // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
          that.$store.commit('changelogin');
        } catch (error) {
          // alert(error.message);
          that.errorM = error.message;
          that.error_b = true;
          that.TOvercode(); //获取验证码
          that.registered.captcha = '';
        } finally {
          that.loadCli = false;
        }
      } else if (vuex.userStatus == 'forget') {
        let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!reg.test(that.registered.username)) {
          // alert('邮箱格式不正确');
          that.errorM = '邮箱格式不正确';
          that.error_b = true;
          that.TOvercode(); //获取验证码
          that.loadCli = false;
          return false;
        }
        try {
          let data = await fetch.api_resetPasswordByEmail({
            email: that.registered.username,
            new_password: that.registered.password,
            captcha: that.phrase_ver,
            phrase_id: that.phrase_id,
          });
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
          // alert('重置成功');
          that.errorM = '重置成功';
          that.error_b = true;
          that.YS.gotoVip(0, 'close', 'loginReg');
          // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
          that.$store.commit('changelogin');
        } catch (error) {
          // alert(error.message);
          that.errorM = error.message;
          that.error_b = true;
          that.TOvercode(); //获取验证码
        } finally {
          that.loadCli = false;
        }
      }
      //登录
      else if (vuex.userStatus == 'userLogin') {
        if (that.registered.username == '' || that.registered.username == null) {
          // alert('请输入有效的用户名');
          that.errorM = '请输入有效的用户名';
          that.error_b = true;
          that.TOvercode(); //获取验证码
          that.loadCli = false;
          return false;
        }
        try {
          let data = await fetch.api_login(that.registered);
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
          that.YS.closeallWin(); //关闭所有弹窗
          that.YS.gotoVip({}, 'close', 'loginReg'); //关闭登录弹窗
          // fetch.api_info({}).then((data) => {
          //   vuex.user = data.user;
          //   console.log('api_info' + data);
          // });
          // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
          that.getPlaylist(); //获取播放历史记录列表
          that.getmesslist(); // 获取通知消息列表
          that.$store.commit('changelogin');
          //继续登录之前的步骤
          that.$store.commit('useloginBefore');

          //这里填写登录后要做什么
          that.$store.commit('getisvipCode');
          switch (that.$route.path) {
            case '/play.html':
              //获取收藏，点赞点踩
              that.$parent.getloginplay();
              break;
            case '/fil.html':
              //获取收藏
              that.$parent.getVideolist();
              break;
            case '/':
              //获取收藏
              that.$parent.getRLfav();
              break;
            case '/.html':
              //获取收藏
              that.$parent.getRLfav();
              break;

            default:
              break;
          }
        } catch (error) {
          // alert(error.message);
          that.errorM = error.message;
          that.error_b = true;
          that.registered.captcha = '';
          that.TOvercode(); //获取验证码
        } finally {
          that.loadCli = false;
        }
      }
    },
    //获取播放历史记录列表
    async getPlaylist() {
      try {
        if (that.$store.state.playbody.video_history_total === null) {
          let data = await fetch.api_videoHistory({ page: 1, pagesize: 8 });
          that.$store.commit('changeEvery', {
            key: 'playbody',
            value: data.video_history_list,
            key2: 'playList',
          });
          that.$store.commit('changeEvery', {
            key: 'playbody',
            value: data.video_history_total,
            key2: 'video_history_total',
          });
        }
      } catch (error) {
        console.log(typeof that.$store.state.playbody.video_history_total);
      }
    },
    // 获取消息列表
    async getmesslist() {
      try {
        if (that.$store.state.message.message_total === null) {
          let data = await fetch.api_messagelist({ unread: 1 });
          that.message_total = data.message_total;
          that.$store.commit('changeEvery', {
            key: 'message',
            value: data.message_list,
            key2: 'message_list',
          });
          that.$store.commit('changeEvery', {
            key: 'message',
            value: data.message_total,
            key2: 'message_total',
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    //点击搜索框，获取视频热门搜索词
    async clickSearchinput(e) {
      try {
        //先判断弹窗是否关闭
        // if (!that.$store.state.showWindows.navSearch) {
        //   that.YS.goDown(e, 'navSearch');
        // }
        that.YS.goDown(e, 'navSearch');
      } catch (error) {
        console.log(error);
      }
    },
    //获取视频热门搜索词
    async getSearchinput() {
      try {
        let data = await fetch.api_videokeyword({});
        that.keywork_list = data.keywork_list;
      } catch (error) {
        console.log(error);
      }
    },

    // 点击通知列表中的消息
    cliNotice(index, id) {
      that.YS.routetogo({
        path: '/message',
        query: { index, id },
      });
      // this.$parent.openmess(index, id);
    },
    clihis(index, id) {
      for (let v in vuex.showWindows) {
        that.$store.commit('changeEvery', { key: 'showWindows', value: false, key2: v });
      }
      that.YS.routetogo(
        {
          path: '/play',
          query: { video_id: id },
        },
        true,
      );
    },
    //获取邮箱验证码
    async getmailver() {
      try {
        if (vuex.code.count === '验证码') {
          let data = await fetch.api_emailCode({ email: that.registered.username });

          that.YS.getCode(that.registered.username);
          that.phrase_id = data.phrase_id;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取可用的支付方式
    async paymentmethod() {
      try {
        if (that.payType === null || !that.payType[0]) {
          let data = await fetch.api_paymentmethod({});

          // that.$store.commit('changeEvery', { key: 'payType', value: data.payment_method_list });
          if (data) {
            that.payType = data.payment_method_list;
            //存储至本地
            that.YS.savestore('payType', data.payment_method_list, 600);
          }

          // //储存本地
          // let payTypeapp = {};
          // var time = new Date().getTime() / 1000;
          // time += 60 * 10; //有效期10分钟
          // payTypeapp.payment_method_list = data.payment_method_list;
          // payTypeapp.time = time;
          // localStorage.setItem('payType', JSON.stringify(payTypeapp));
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取点卡种类   周卡/月卡
    async vipCardcategory() {
      try {
        if (that.payList === null || !that.payList[0]) {
          let data = await fetch.api_vipCardcategory({});
          if (data) {
            that.payList = data.vip_card_category_list;
            //存储至本地
            that.YS.savestore('payList', data.vip_card_category_list, 600);
          }
          //默认选中第一个
          that.gotoPay.vip_card_category_id = data.vip_card_category_list[0].id;
          // data;
          // that.payList = data.vip_card_category_list;
          // that.$store.commit('changeEvery', { key: 'payList', value: data.vip_card_category_list });
        } else {
          //默认选中第一个
          that.gotoPay.vip_card_category_id = that.payList[0].id;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取首页悬浮菜单内容
    async floating() {
      try {
        if (!that.floating_list) {
          let data = await fetch.api_floating({});
          that.floating_list = data.floating_list;
          //存储至本地
          that.YS.savestore('floating_list', data.floating_list, 600);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //接口——购买会员卡/前去支付
    async paymentpurchaseVipCard() {
      try {
        let data = await fetch.api_paymentpurchaseVipCard(that.gotoPay);
        that.rightnowPay = data;
        // that.YS.gotoVip(0, 'open', 'payresult'); //确认支付结果弹窗
        // window.open(data.redirect_url);
        window.location.href = data.redirect_url;
      } catch (error) {
        // alert(error.message);
        that.usepubilDialog({ title: error.message, code: false });

        that.pay_index.bottom = null;
        that.payCli = false;
      }
    },
    //第二步选择
    payselect2(item, index) {
      if (!that.gotoPay.vip_card_category_id) {
        //默认选中第一个
        that.gotoPay.vip_card_category_id = that.payList[0].id;
        that.pay_index.top = 0;
      }

      that.gotoPay.payment_method_id = item.id;
      that.pay_index.bottom = index;
      that.nowsymbol = item.symbol;
    },
    //前往支付
    gopay() {
      if (that.payCli) return;
      that.payCli = true;
      // 卡密支付
      if (that.gotoPay.payment_method_id == '' || that.gotoPay.payment_method_id == null) {
        // alert('请选择支付方式');
        that.usepubilDialog({ title: '请选择支付方式', code: false });
        that.payCli = false;
        return;
      }
      if (that.nowsymbol == 'vip-card') {
        that.YS.gotoVip(1, 'open', 'vipcardWIN');
        that.payCli = false;
        // that.$store.commit('changeEvery', { key: 'showlogin', value: true, key2: 'vipcardWIN' });
      } else if (that.nowsymbol == 'artificial') {
        //人工
        // that.YS.gotoVip(1, 'open', 'vipcardWIN');
        that.symbol = 'purchase_vip_card';
        that.customerService();
        that.YS.gotoVip({}, 'open', 'kefugo');
        that.payCli = false;
      } else {
        that.paymentpurchaseVipCard();
      }
    },
    //打开VIP弹窗，点击立即开通vip
    async wantVIP() {
      if (that.$store.state.islogin) {
        that.vipCardcategory(); //获取点卡种类
        that.paymentmethod(); //获取支付方式
        that.checkRepeat();
        that.YS.gotoVip({}, 'open', 'vipReg');
      } else {
        //添加方法到登录前置
        that.$store.commit('addloginBefore', () => {
          that.YS.gotoVip({}, 'open', 'vipReg');
          that.vipCardcategory(); //获取点卡种类
          that.paymentmethod(); //获取支付方式
        });

        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.TOvercode(); //获取验证码
      }
    },
    //api通过标识取页面内容
    // 服务条款          service-terms
    // 充值协议          recharge-agrrement
    // 关于我们          about-us
    // 联系我们          contact-us
    // 加入我们          join-us
    // 广告投放          advertising
    async getpageapi(type, win) {
      try {
        let data = await fetch.api_page({ symbol: type });
        if (data && data.page.rich == 1) {
          //富文本
          that.getpage = data.page;
          // that.getpage.content = data.page.content;
          that.YS.gotoVip(1, 'open', win);
        } else if (data && data.page.rich == 0) {
          //纯文字

          that.getpage = data.page;
          that.getpage.content = data.page.content.replace(/。/g, '。<br>');
          that.YS.gotoVip(1, 'open', win);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //提交视频报错反馈
    async cliplayerror() {
      try {
        if (that.loadCli) return false;
        that.loadCli = true;
        var obj = {};
        obj.quality = that.$parent.videonews.quality;
        obj.video_fragment_id = that.$parent.videonews.video_fragment_id;
        obj.video_id = that.$parent.videonews.video_id;
        obj.content = that.playerrorText;
        obj.client_type = 1; //pc客户端
        obj.os = that.YS.getOS().name + '/' + that.YS.getOS().versionStr; //系统
        obj.screen_width = document.documentElement.clientWidth || document.body.clientWidth;
        obj.screen_height = document.documentElement.clientHeight || document.body.clientHeight;
        obj.brower = that.YS.getBrowserInfo()[0] || that.YS.getBrowserInfo() || '';
        obj.remarks = that.$parent.guide + that.$parent.guideError;

        await fetch.api_videoreport(obj);
        that.usepubilDialog({ title: '反馈成功', code: true });
        that.YS.gotoVip(1, 'close', 'playerror');
      } catch (error) {
        that.YS.gotoVip(1, 'close', 'playerror');
        that.usepubilDialog({ title: error.message, code: false });
        // console.log(error);
      } finally {
        that.loadCli = false;
      }
    },
    //查看支付结果
    seepayres() {
      if (that.$route.path == '/recharge') {
        // that.YS.closeallLogin(); //关闭所有弹窗
        // that.YS.closeallWin(); //关闭所有弹窗
        that.YS.gotoVip(1, 'close', 'payresult');
        that.YS.gotoVip(1, 'close', 'confirn');
        that.YS.gotoVip(1, 'close', 'vipReg');
      } else {
        that.YS.routetogo({ path: '/recharge' });
        // window.location.href = '/recharge';
      }
    },
    //vip点卡激活事件
    async vipcardcli() {
      try {
        let data = await fetch.api_vipCardactivate({ cipher: that.vipcardact });
        var tem_local = JSON.parse(localStorage.getItem('user'));
        tem_local.vip_expired_time = data.user.vip_expired_time;
        localStorage.setItem('user', JSON.stringify(tem_local));
        that.$store.commit('changelogin');
        // alert('充值成功');

        that.usepubilDialog({ title: '充值成功', type: 'vip-card', code: true });

        that.YS.gotoVip(1, 'close', 'vipcardWIN');
        that.YS.gotoVip(1, 'close', 'vipReg');
      } catch (error) {
        that.usepubilDialog({ title: error.message, type: 'vipcard', code: false });
      }
    },
    //使用公共弹窗
    // { title: '充值成功', type: 'vipcard', code: true }
    usepubilDialog(data) {
      if (data.code) {
        that.publicSuccess = data;
      } else {
        that.publicFailure = data;
      }
      // that.publicDialog.type = type;
      this.$nextTick(() => {
        if (data.code) {
          that.YS.gotoVip({}, 'open', 'success');
        } else {
          that.YS.gotoVip({}, 'open', 'failure');
        }
      });
    },
    //悬浮窗点击事件
    clifloating(type) {
      switch (type) {
        case 'theme': //切换主题 开灯/关灯
          // that.offLight = !that.offLight;
          that.$store.commit('loghtBool');
          if (that.offLight) {
            // document.getElementById('app3').classList.add('app_offLight');
            document.getElementsByTagName('body')[0].classList.add('app_offLight');
            document.getElementById('topNav').classList.remove('top-nav-white');
          } else {
            // document.getElementById('app3').classList.remove('app_offLight');
            document.getElementsByTagName('body')[0].classList.remove('app_offLight');
          }
          break;
        case 'vip': //充值会员
          that.wantVIP();
          break;
        case 'ask-for-video': //求片
          //求片的id是4=>求片的标识是ask-for-video
          if (that.$route.path == '/sap') {
            that.$parent.opi.symbol = 'ask-for-video';
          } else {
            //再判断是否登录
            if (that.$store.state.islogin) {
              that.YS.routetogo({ path: '/sap', query: { symbol: 'ask-for-video' } });
            } else {
              that.$store.commit('addloginBefore', () => {
                that.YS.routetogo({ path: '/sap', query: { symbol: 'ask-for-video' } });
              });
              that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
              that.TOvercode(); //获取验证码
            }
          }
          break;
        case 'download': //下载APP
          that.YS.routetogo({ path: '/downapp' }, true);
          break;
        case 'top': //返回顶部
          that.YS.toTop2();
          break;
        case 'mobile': //访问手机站
          // that.YS.routetogo({ path: '/mhome' });
          window.location.href = '/mhome';
          break;
        case 'to-classic':
          // that.YS.routetogo({ path: '/mhome' });
          window.location.href = window.g.OLD_URL;
          break;

        default:
          break;
      }
    },
    //清空历史记录
    clearhis() {
      that.smalltype = 'his';
      that.YS.gotoVip({}, 'open', 'small');
      localStorage.removeItem('not_logged_history');
    },
    //清空消息
    clearmess() {
      that.smalltype = 'mess';
      that.YS.gotoVip({}, 'open', 'small');
    },
    //检查是否有重复提交了订单
    checkRepeat() {
      if (localStorage.getItem('pay_ok')) {
        if ((localStorage.getItem('pay_ok') + 24 * 60 * 60) * 1000 > new Date().getTime()) {
          // that.openandclo('mpayresult', true);
          that.YS.gotoVip(0, 'open', 'payresult'); //确认支付结果弹窗
          that.pay_ok = Math.round(localStorage.getItem('pay_ok'));
        }
      }
    },
    //小弹窗的确认事件
    async smallbutton() {
      try {
        if (that.smalltype == 'mess') {
          //消息记录
          that.tophis = false;
          that.YS.gotoVip({}, 'close', 'small');
          that.message_total = 0;
          await that.api.user.api_messagereadAll({});
        } else if (that.smalltype == 'search') {
          that.searchTop = [];
          localStorage.setItem('topsearchword', []);
          that.YS.gotoVip({}, 'close', 'small');
        } else if (that.smalltype == 'his') {
          //历史记录
          that.topwatch = false;
          that.YS.gotoVip({}, 'close', 'small');
          await that.api.user.api_useremptyVideoHistory({});
        } else if (that.smalltype == 'edit') {
          let deit_data = that.$parent.hisIDList.join(',');
          //编辑批量删除历史记录
          await that.api.user.api_useremptyVideoHistory({
            selected: 1,
            ids: deit_data,
          });
          that.YS.gotoVip({}, 'close', 'small');
          that.$parent.getPlaylist();
          that.$parent.hisEdit(true);
          that.messTop = '删除成功';
          that.mess_show = true;
          setTimeout(() => {
            that.mess_show = false;
          }, 3000);
        } else if (that.smalltype == 'favEdit') {
          let deit_data = that.$parent.favIDList.join(',');
          //编辑批量删除历史记录
          await that.api.user.api_useremptyVideoFavorite({
            selected: 1,
            ids: deit_data,
          });
          that.YS.gotoVip({}, 'close', 'small');
          that.$parent.getFavlist();
          that.$parent.favEdit(true);
          that.messTop = '删除成功';
          that.mess_show = true;
          setTimeout(() => {
            that.mess_show = false;
          }, 3000);
        }
      } catch (error) {
        // alert(error.message);
        that.usepubilDialog({ title: error.message, code: false });
      }
    },
    //清空登录框
    clearLoginInput() {
      (that.registered.username = ''),
        (that.registered.password = ''),
        (that.registered.captcha = '');
    },
    //点击下拉框里的按钮
    cliUserDown(e, type) {
      switch (type) {
        case 'vip':
          break;
        case 'unvip':
          that.wantVIP();
          break;
        case 'center':
          that.YS.routetogo({ path: '/account' });
          break;
        case 'outlogin':
          that.YS.gotoVip({}, 'open', 'outlogin');
          break;

        default:
          break;
      }
      e.stopPropagation();
    },
    //退出登录
    async toOutlogin() {
      try {
        that.api.user.api_signout({});
        that.$store.state.islogin = false;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        that.$store.commit('changelogin');
        that.YS.gotoVip({}, 'close', 'outlogin');
        that.usepubilDialog({ title: '退出登录成功', code: true });
        // that.YS.routetogo({ path: '/' });
        window.location.reload();
      } catch (error) {
        console.log(error.message);
      }
    },
    //获取客服信息
    async customerService() {
      that.kefuimg = '';
      that.telegram = '';
      let data = await fetch.api_customerServiceinfo({ symbol: that.symbol });
      that.customer_service_list = data.customer_service_list;
      if (data.customer_service_list) {
        that.kefuimg = data.customer_service_list.wechat_qrcode;
        that.telegram = data.customer_service_list.qq;
      }
    },
    //失败公用弹窗按钮
    publicButton() {
      var type = that.publicFailure.type;
      if (type) {
        switch (type) {
          case 'needvip':
            //需要开通vip
            that.wantVIP();
            that.publicFailure.failbutton = '';
            break;
          // case 'toLogin':
          //   //前往登录
          //   debugger;
          //   that.publicFailure.failbutton = '';
          //   break;

          default:
            break;
        }
      }
      that.YS.gotoVip({}, 'close', 'failure');
    },
    //获取支付结果1，入口
    async clivipres() {
      window.addEventListener('scroll', this.webwindowScroll);
    },
    //重复获取支付结果，重复事件
    getvipres() {
      //取判断标准老的vip时间，在这之前获取用户信息都会记录老vip时间
      if (localStorage.getItem('vtime')) {
        that.vipOldtime = JSON.parse(localStorage.getItem('vtime'));
      } else {
        //获取不到取user里的
        if (localStorage.getItem('user')) {
          that.vipOldtime = JSON.parse(localStorage.getItem('user')).vip_expired_time;
          localStorage.setItem('vtime', JSON.stringify(that.vipOldtime));
        }
      }
      var set2 = setInterval(() => {
        if (that.$store.state.showlogin.payres == false) {
          that.YS.gotoVip({}, 'open', 'payres');
        }
        if (that.geiviptime === 0) {
          that.api.user.api_info({}).then((data) => {
            var tem_local = JSON.parse(localStorage.getItem('user'));
            tem_local.vip_expired_time = data.user.vip_expired_time;
            localStorage.setItem('user', JSON.stringify(tem_local));
            that.$store.commit('changelogin');
            that.$store.commit('getisvipCode');
            that.geivipcount += 1;
            that.vipNewtime = data.user.vip_expired_time;

            if (that.isvip && that.vipNewtime != that.vipOldtime) {
              clearInterval(set2);
              that.YS.gotoVip({}, 'close', 'payres');
              that.usepubilDialog({ title: '充值成功!', code: true, type: 'recharge' });
              localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
              localStorage.removeItem('pay_ok');
            }
          });
          that.geiviptime = 10;
        } else if (that.geivipcount > 1) {
          clearInterval(set2);
          that.YS.gotoVip({}, 'close', 'payres');
          that.usepubilDialog({
            title:
              '获取结果失败，请刷新重试！如确认支付完成可重新登录查看状态。\n（PAYPAL支付延迟较大，建议稍后再刷新重试）',
            code: false,
          });
        } else {
          that.geiviptime -= 1;
        }
      }, 1001);
      // setTimeout(() => {
      //   if (that.geiviptime === 0) {
      //     that.api.user.api_info({}).then((data) => {
      //       var tem_local = JSON.parse(localStorage.getItem('user'));
      //       tem_local.vip_expired_time = data.user.vip_expired_time;
      //       localStorage.setItem('user', JSON.stringify(tem_local));
      //       that.$store.commit('changelogin');
      //       if (that.isvip) {
      //         that.usepubilDialog({ title: '您已经是vip', code: true });
      //         that.YS.gotoVip({}, 'close', 'payres');
      //       }
      //     });
      //     that.geiviptime = 5;
      //     that.getvipres();
      //   } else {
      //     that.geiviptime -= 1;
      //     that.getvipres();
      //   }
      // }, 1000);
    },
  },
};
</script>
<style lang="scss">
.vercode-input,
.code-input {
  width: 315px !important;
}
.top-down-svg-180 {
  transform: rotate(180deg);
  path {
    stroke: #149bec !important;
  }
}
</style>
