window.g = {
  // BASE_URL: 'http://51.79.223.179:8012', //测试服
  BASE_URL: 'https://api-h5.uvod.tv', //正式服
  // BASE_URL: 'http://144.217.73.97:8410', //正式服

  // BASE_URL: 'http://192.168.31.8:8012', //本地
  // BASE_URL: 'http://192.168.1.14:8810', //本地

  WEBSOCKET_URL: 'ws://ws.uvod.tv:9501', //正式服 - websocket地址
  // WEBSOCKET_URL: 'ws://192.168.1.8:9501', //websocket地址

  OLD_URL: 'https://classic.uvod.tv', //旧版网址

  WEB_URL: 'https://www.uvod.tv', // 正式服首页
  // WEB_URL: 'http://localhost:8080', // 正式服首页

  MOBILE_URL: 'https://m.uvod.tv', //正式服手机端
  // MOBILE_URL: 'http://localhost:8080', //正式服手机端

  // 午夜版地址
  WY_WEB_RUL: 'https://www.wyvod.tv',
  WY_MOBILE_RUL: 'https://m.wyvod.tv',

  // 儿童版地址
  CHILD_WEB_RUL: 'https://kid.uvod.tv',
  CHILD_MOBILE_RUL: 'https://kidm.uvod.tv',

  isNight: true,

  axios_console: false,
  // axios_console: false,
};
