import request from './request';

export default {
  list(data) {
    return request.post('/user/list', data);
  },
  //获取用户信息
  api_info(data) {
    return request.post('/user/info', data);
  },
  save(data) {
    return request.post('/user/save', data);
  },
  enable(data) {
    return request.post('/user/enable', data);
  },
  disable(data) {
    return request.post('/user/disable', data);
  },
  forbidden(data) {
    return request.post('/user/forbidden', data);
  },
  delete(data) {
    return request.post('/user/delete', data);
  },

  changePassword(data) {
    return request.post('/user/changePassword', data);
  },
  //验证码获取
  api_verCode(data) {
    return request.post('/captcha', data);
  },
  //注册
  api_registered(data) {
    return request.post('/passport/signup', data);
  },
  //通过邮箱注册
  api_passportsignupByEmail(data) {
    return request.post('/passport/signupByEmail', data);
  },
  //登录
  api_login(data) {
    return request.post('/passport/signin', data);
  },
  //退出登录
  api_signout(data) {
    return request.post('/secrity/signout', data);
  },
  //修改昵称
  api_changeNickname(data) {
    return request.post('/user/changeNickname', data);
  },
  //修改密码
  api_changePassword(data) {
    return request.post('/secrity/changePassword', data);
  },
  //通过邮箱重置密码
  api_resetPasswordByEmail(data) {
    return request.post('/passport/resetPasswordByEmail', data);
  },
  //邮箱验证码
  api_emailCode(data) {
    return request.post('/captcha/email', data);
  },
  //修改绑定邮箱
  api_secritybindEmail(data) {
    return request.post('/secrity/bindEmail', data);
  },
  //修改头像
  api_changeAvatar(data) {
    return request.post('/user/changeAvatar', data);
  },
  //观看历史
  api_videoHistory(data) {
    return request.post('/user/videoHistory', data);
  },
  //收藏的视频
  api_videoFavorite(data) {
    return request.post('/user/videoFavorite', data);
  },
  //充值记录
  api_rechargeRecord(data) {
    return request.post('/user/rechargeRecord', data);
  },
  //帮助中心分类(全部)
  api_category(data) {
    return request.post('/faq/category', data);
  },
  //帮助中心列表
  api_categoryid(data) {
    return request.post('/faq/list', data);
  },
  //视频热搜词
  api_videokeyword(data) {
    return request.post('/video/keyword', data);
  },
  //分类
  api_categoryhome(data) {
    return request.post('/video/category', data);
  },
  //消息列表
  api_messagelist(data) {
    return request.post('/message/list', data);
  },
  //搜索视频列表
  api_videolist(data) {
    return request.post('/video/list', data);
  },
  //标记为已读
  api_messageread(data) {
    return request.post('/message/read', data);
  },
  //获取视频信息
  api_videoinfo(data) {
    return request.post('/video/info', data);
  },
  //播放资源地址
  api_videosource(data) {
    return request.post('/video/source', data);
  },
  //最新资源
  api_videolatest(data) {
    return request.post('/video/latest', data);
  },
  //排行榜
  api_videorank(data) {
    return request.post('/video/rank', data);
  },
  //视频筛选词
  api_videofilter(data) {
    return request.post('/video/filter', data);
  },
  //轮播图
  api_banner(data) {
    return request.post('/banner', data);
  },
  //视频收藏
  api_videofavorite(data) {
    return request.post('/video/favorite', data);
  },
  //取消视频收藏
  api_videodeleteFavorite(data) {
    return request.post('/video/deleteFavorite', data);
  },
  //评论列表
  api_videocomment(data) {
    return request.post('/video/comment', data);
  },
  //发表评论
  api_videoevaluate(data) {
    return request.post('/video/evaluate', data);
  },
  //发表评价
  api_videopostComment(data) {
    return request.post('/video/postComment', data);
  },
  //获取全部广告
  api_adall(data) {
    return request.post('/ad/all', data);
  },
  //获取一个广告
  api_ad(data) {
    return request.post('/ad', data);
  },
  //获取支付的点卡种类
  api_vipCardcategory(data) {
    return request.post('/vipCard/category', data);
  },
  //激活点卡
  api_vipCardactivate(data) {
    return request.post('/vipCard/activate', data);
  },
  //可用的支付方式
  api_paymentmethod(data) {
    return request.post('/payment/method', data);
  },
  //购买会员卡/前往支付
  api_paymentpurchaseVipCard(data) {
    return request.post('/payment/purchaseVipCard', data);
  },
  //首页悬浮
  api_floating(data) {
    return request.post('/floating', data);
  },
  //反馈类型
  api_feedbackcategory(data) {
    return request.post('/feedback/category', data);
  },
  //提交反馈
  api_feedbackpost(data) {
    return request.post('/feedback/post', data);
  },
  //播放页视频报错反馈提交
  api_videoreport(data) {
    return request.post('/video/report', data);
  },
  //通过标识取页面内容
  api_page(data) {
    return request.post('/page', data);
  },
  //获取全部客服列表
  api_customerServicelist(data) {
    return request.post('/customerService/list', data);
  },
  //获取单个客服信息
  api_customerServiceinfo(data) {
    return request.post('/customerService/info', data);
  },
  //消息-全部标为已读
  api_messagereadAll(data) {
    return request.post('/message/readAll', data);
  },
  //更新播放记录，暂停
  api_userupdateHistory(data) {
    return request.post('/user/updateHistory', data);
  },

  // 移动端
  //走马灯公告
  api_noticemarquee(data) {
    return request.post('/notice/marquee', data);
  },
  //上传头像
  api_uploadavatar(data) {
    return request.post('/upload/avatar', data, { normalRequest: true });
  },

  //二开
  //查看其他用户是否有效
  api_usercheckOtherUser(data) {
    return request.post('/user/checkOtherUser', data);
  },
  //清空/批量删除历史记录
  api_useremptyVideoHistory(data) {
    return request.post('/user/emptyVideoHistory', data);
  },
  //清空/批量删除收藏夹
  api_useremptyVideoFavorite(data) {
    return request.post('/user/emptyVideoFavorite', data);
  },
  //发送视频弹幕
  api_videoBarragepostBarrage(data) {
    return request.post('/videoBarrage/postBarrage', data);
  },
  //获取视频弹幕列表
  api_videoBarragelist(data) {
    return request.post('/videoBarrage/list', data);
  },
  //获取搜索框联想关键词
  api_videosuggest(data) {
    return request.post('/video/suggest', data);
  },
  //检查APP应用新版本
  api_appcheckVersion(data) {
    return request.post('/app/checkVersion', data);
  },
  //统计下载
  api_beforeDownApp(data) {
    return request.post('/appDownload/save', data);
  },
};
