<template>
  <!-- 右侧主体 -->
  <div class="right-body">
    <!-- 每一块子列表 -->
    <div
      class="rb-list"
      :class="'rb-list-' + index"
      v-for="(item, index) in leadRanking"
      :key="index"
      :title="item.title"
    >
      <!-- @click="cliLeadrank(item.id)" -->
      <div class="rb-list-title" v-if="item">
        <span class="rlt-fir">{{ index + 1 }}、</span>
        <a
          :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
          :title="item.title"
          target="_blank"
        >
          {{ item.title }}
        </a>
        <!-- <span class="rlt-sec">{{ Math.floor((item.score * 100) / 10) / 10 }}</span> -->
        <span class="rlt-sec">{{ item.score.toFixed(1) }}</span>
      </div>
      <div class="rb-list-bottom">
        <!-- <a v-for="(name, i) in item.label" :key="i">{{ name }}</a> -->
        <a v-show="item.year">{{ item.year }}</a>
        <a v-show="$store.state.menudist[item.category_id]">
          {{ $store.state.menudist[item.category_id] }}
        </a>
        <a v-show="item.region">{{ item.region }}</a>
        <a v-show="item.language">{{ item.language }}</a>
      </div>
    </div>
  </div>
</template>
<script>
let that;
that;
export default {
  name: 'Leadranking',
  data() {
    return {};
  },
  props: ['leadRanking'],
  created() {
    that = this;
    that;
  },
  methods: {
    cliLeadrank(video_id) {
      that.YS.routetogo({ path: '/play', query: { video_id: video_id } }, true);
      //如果存在播放器dp则为播放页,刷新路由
      // if (that.$parent.dp) {
      //   debugger;
      //   that.$parent.routerRefresh();
      // }
    },
  },
};
</script>
