// 暂时没有任何内容-组件
<template>
  <div class="none">
    <img src="../assets/img/none.png" alt="没有内容展示图片" />
    <span>暂时没有任何内容</span>
  </div>
</template>
<script>
let that;
that;
export default {
  name: 'None',
  data() {
    return {};
  },
  created() {
    that = this;
  },
  methods: {},
};
</script>
