<template>
  <!-- 底部 -->
  <div class="bottomall">
    <div class="bottombody">
      <!-- 底部左侧 -->
      <div class="bbody-left">
        <div class="bb-title">
          <img src="../assets/img/quanqiu.png" alt="全球图标" />
          <a>全球</a>
          <!-- <a href="javascript:void(0)">关于我们</a> -->
          <!-- <a href="javascript:void(0)">诚聘英才</a> -->
          <a @click="usuallyque">常用问题</a>
          <a @click="yijian">意见反馈</a>
          <!-- <a href="javascript:void(0)">充值中心</a> -->
          <a class="b_guanggao" @click="guanggao" @mouseenter="customerService('advertising')">
            广告合作
            <div class="b_guanggao_div">
              <img :src="ggkefuimg" width="150" height="150" alt="广告合作图标" />
              <span class="b_g_1">打开微信扫一扫添加</span>
              <span class="b_g_2">在线时间：{{ timeText }}</span>
              <span class="b_g_2 b_g_2-center">
                {{ timeArea }}
              </span>
            </div>
          </a>
          <!-- <a href="javascript:void(0)">联系我们</a> -->
          <!-- <a href="javascript:void(0)">服务条款</a> -->
          <!-- <a href="javascript:void(0)">充值协议</a> -->
          <a class="b_huiyuan" @click="huiyuan" @mouseenter="customerService('purchase_vip_card')">
            会员采购

            <div class="b_guanggao_div1">
              <img :src="ggkefuimg" width="150" height="150" alt="会员采购图标" />
              <span class="b_g_1">打开微信扫一扫添加</span>
              <span class="b_g_2">在线时间：{{ timeText }}</span>
              <span class="b_g_2 b_g_2-center">
                {{ timeArea }}
              </span>
            </div>
          </a>
        </div>
        <div class="bb-nodes">
          <span>
            版权声明：如果来函说明本网站提供内容本人或法人版权所有。本网站在核实后，有权先行撤除，以保护版权拥有者的权益。
            <br />
            邮箱地址：uvod@gmail.com
            <br />
            Uvod.tv 版权所有，未经授权禁止链接、复制或建立
          </span>
        </div>
        <div class="bb-bottom">
          <span>Copyright 2010-2021 uvod.tv Allrights Reserved.</span>
        </div>
      </div>
      <!-- 底部右侧 -->
      <div class="bbody-right">
        <div class="br-body">
          <!-- <div class="brb-left">
            <img src="../assets/img/Telegram.png" />
            <p>Telegram客服</p>
          </div> -->
          <div class="brb-right">
            <!-- 联系客服 -->
            <div class="brb-right-left" @click="callkefu">
              <div>
                <div class="brbr_1">
                  <img
                    class="brbr-img"
                    height="22"
                    width="22"
                    src="../assets/img/kefulogo.png"
                    alt="联系客服图标"
                  />
                  <span>联系客服</span>
                </div>
                <p class="brbr_2">在线时间：{{ timeText }}</p>
                <p class="brbr_2">
                  {{ timeArea }}
                </p>
              </div>
              <!-- <img class="brbrr-img" src="../assets/img/download.png" /> -->
            </div>
            <!-- 苹果客户端下载 -->
            <!-- <div class="brb-right-left">
              <div>
                <img class="brbr-img" src="../assets/img/iphone-logo.png" />
                <p>ios客户端下载</p>
              </div>
              <img class="brbrr-img" src="../assets/img/download.png" />
            </div> -->
            <!-- 安卓客户端下载 -->
            <!-- <div class="brb-right-left">
              <div>
                <img class="brbr-img" src="../assets/img/android-logo.png" />
                <p>安卓客户端下载</p>
              </div>
              <img class="brbrr-img" src="../assets/img/download.png" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
export default {
  name: 'Bottom',
  data() {
    return {
      timeText: '',
      timeArea: '',
      ggkefuimg: '', //客服二维码
      symbol: '', //客服标识
      customer_service_list: [], //客服信息
    };
  },
  created() {
    that = this;
    that;
    //跟据用户时区不同，计算用户当前的国家时间

    //VUEX中定义在线时间
    // 获取用户地区与世界的时间差;
    try {
      let timeCha = -(new Date().getTimezoneOffset() / 60);
      // debugger;
      let stratTime = that.$store.state.onlineTime.startTime + timeCha;
      let endTime = that.$store.state.onlineTime.endTime + timeCha;
      let countNum = 0;
      while (stratTime < 0 && countNum < 10) {
        stratTime += 24;
        countNum += 1;
      }
      while (stratTime >= 24 && countNum < 10) {
        stratTime -= 24;
        countNum += 1;
      }
      while (endTime < 0 && countNum < 10) {
        endTime += 24;
        countNum += 1;
      }
      while (endTime > 24 && countNum < 10) {
        endTime -= 24;
        countNum += 1;
      }
      let string_stratTime = stratTime.toString();
      let string_endTime = endTime.toString();
      if (string_stratTime.length < 2) string_stratTime = '0' + string_stratTime;
      if (string_endTime.length < 2) string_endTime = '0' + string_endTime;
      that.timeText = string_stratTime + ':00-' + string_endTime + ':00';
    } catch (error) {
      console.log(error.message);
    }
    try {
      let linshiArea = new Date().toString().split('(')[1].split(')')[0];
      that.timeArea = linshiArea;
    } catch (error) {
      console.log(error.message);
    }
  },
  methods: {
    //常见问题
    usuallyque() {
      that.YS.routetogo({ path: '/help' });
      // if (that.$store.state.islogin) {
      //   that.YS.routetogo({ path: '/help' });
      // } else {
      //   that.$store.commit('addloginBefore', () => {
      //     that.YS.routetogo({ path: '/help' });
      //   });
      //   that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
      //   that.$parent.$refs.top.TOvercode(); //获取验证码
      // }
    },
    //意见反馈
    yijian() {
      if (that.$store.state.islogin) {
        that.YS.routetogo({ path: '/opinion' });
      } else {
        that.$store.commit('addloginBefore', () => {
          that.YS.routetogo({ path: '/opinion' });
        });
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.$parent.$refs.top.TOvercode(); //获取验证码
      }
    },
    //广告合作
    guanggao() {
      // that.symbol='advertising'
      that.$parent.$refs.top.symbol = 'advertising';
      that.$parent.$refs.top.customerService();

      that.YS.gotoVip({}, 'open', 'kefugo');
      // if (that.$store.state.islogin) {
      //   that.YS.routetogo({ path: '/help', query: { kefu: 'advertising' } });
      // } else {
      //   //添加登录后的方法队列
      //   that.$store.commit('addloginBefore', () => {
      //     that.YS.routetogo({ path: '/help', query: { kefu: 'advertising' } });
      //   });
      //   that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
      //   that.$parent.$refs.top.TOvercode(); //获取验证码
      // }
    },
    //会员采购
    huiyuan() {
      that.$parent.$refs.top.symbol = 'purchase_vip_card';
      that.$parent.$refs.top.customerService();
      that.YS.gotoVip({}, 'open', 'kefugo');
      // if (that.$store.state.islogin) {
      //   that.YS.gotoVip(1, 'open', 'vipReg');
      // } else {
      //   //添加方法到登录前置
      //   that.$store.commit('addloginBefore', () => {
      //     that.YS.gotoVip(1, 'open', 'vipReg');
      //   });
      //   that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
      //   that.$parent.$refs.top.TOvercode(); //获取验证码
      // }
    },
    //联系客服
    callkefu() {
      that.$parent.$refs.top.symbol = 'faq';
      that.$parent.$refs.top.customerService();
      that.YS.gotoVip({}, 'open', 'kefugo');
    },
    //获取客服信息
    async customerService(type) {
      try {
        let data = await that.api.user.api_customerServiceinfo({ symbol: type });
        that.customer_service_list = data.customer_service_list;
        if (data.customer_service_list) {
          that.ggkefuimg = data.customer_service_list.wechat_qrcode;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
