/**
 * 字符串
 */

export default {
  // 生成随机字符串
  random(len = 32, chars = null) {
    if (!chars) {
      chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    }

    const maxPos = chars.length;
    let s = '';
    for (let i = 0; i < len; i++) {
      s += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return s;
  },
};
